<template>
    <Operators />
</template>
  
<script>
import Operators from "@/components/Configurations/Operators";

export default {
    components: {
        Operators,
    },
};
</script>
  