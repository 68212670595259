import Vue from "vue";
import { containersService } from "@/api_services/containers.service";

let initialState = {
  list: [],
  loading: false,
};

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    setContainers(state, containers) {
      state.list = containers;
    },
    updateContainers(state, actions) {
      actions.forEach(({ operation, record }) => {
        let foundIndex = state.list.findIndex((c) => c.id === record.id);
        if (operation == "add" || operation == "update") {
          if (foundIndex >= 0) {
            Vue.set(state.list, foundIndex, record);
          } else {
            state.list.push(record);
          }
        } else if (operation == "remove") {
          if (foundIndex !== null || typeof foundIndex == "undefined") {
            state.list.splice(foundIndex, 1);
          }
        }
      });
    },
    setGeoJsonContainers(state, geojson) {
      state.geojson = geojson;
    },
    setLoading(state, status) {
      state.loading = status;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    UPDATE_CONTAINERS: (context, containers) => {
      return new Promise((resolve) => {
        context.commit("updateContainers", containers);
        resolve(containers);
      });
    },
    GET_CONTAINER_INFO_BY_BIC: (context, bic) => {
      return new Promise((resolve, reject) => {
        containersService
          .getContainerInfoByBic(bic)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET_STATE_CONTAINERS: (context) => {
      context.commit("resetState");
    },
  },
};
