<template>
  <div class="map-container">
    <div ref="map" class="map" />

    <div
      style="position: absolute; top: 20px; left: 20px"
      v-if="!$vuetify.breakpoint.xs"
    >
      <div class="mb-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small @click.stop="goToHome()" v-bind="attrs" v-on="on">
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </template>
          <span>{{ this.$t("global.home") }}</span>
        </v-tooltip>
      </div>
    </div>

    <v-card
      outlined
      elevation="5"
      style="position: absolute; bottom: 20px; left: 20px; min-height:100px; max-height: calc(100% - 100px);"
      class="py-2 d-flex flex-column"
    >
      <v-card-title class="text-overline my-0 py-0 text-center  font-weight-black">{{ this.$t("global.legend") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-0 py-0 px-0 overflow-y-auto" style="min-height:50px; max-height: 300px;">
        <v-list dense>
          <v-list-item v-for="(segregation, i) in segregations" :key="i">
            <v-list-item-avatar tile size="15">
              <v-icon :style="{ color: segregation.color }"
                >mdi-texture-box</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content class="text-caption font-weight-bold">
              {{ segregation.label }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import "maptalks/dist/maptalks.css";

import * as maptalks from "maptalks";
import * as THREE from "three";
import { ThreeLayer } from "maptalks.three";
import { GroupGLLayer } from "@maptalks/gl";
import configs from "@/helpers/configs";
import { segregations } from "@/helpers/segregations";

const sceneConfig = {
  postProcess: {
    enable: true,
    antialias: { enable: true },
  },
};

export default {
  props: ["refMap", "refThreeLayer", "refShipsLayer"],
  data: () => ({
    groupLayer: null,
    segregations: segregations,
  }),

  computed: {
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },

    threeLayer: {
      get() {
        return this.refThreeLayer;
      },
      set(val) {
        this.$emit("update:refThreeLayer", val);
      },
    },

    shipsLayer: {
      get() {
        return this.refShipsLayer;
      },
      set(val) {
        this.$emit("update:refShipsLayer", val);
      },
    },
  },

  beforeDestroy() {
    this.map.removeLayer(this.groupLayer);
    this.map.removeLayer(this.threeLayer);
    this.$root.$off("goTo", this.goTo);
  },

  mounted() {
    this.$root.$on("goTo", this.goTo);
    this.initMap();
  },

  watch: {
    threeLayer(val) {
      if (!!val) {
        this.threeLayer.prepareToDraw = (gl, scene, camera) => {
          var light = new THREE.DirectionalLight(0xffffff);
          light.position.set(0, -10, 10).normalize();
          scene.add(light);
          scene.add(new THREE.AmbientLight("#fff", 0.3));
          this.animate();
        };

        this.shipsLayer.prepareToDraw = (gl, scene, camera) => {
          var light = new THREE.DirectionalLight(0xffffff);
          light.position.set(0, -10, 10).normalize();
          scene.add(light);
          scene.add(new THREE.AmbientLight("#fff", 0.5));
          this.animateShipsLayer();
        };

        let groupLayer = new GroupGLLayer(
          "groupGLLayer",
          [this.threeLayer, this.shipsLayer],
          { sceneConfig }
        );
        this.map.addLayer(groupLayer);
      }
    },
  },

  methods: {
    initMap() {
      let locode = configs.getLocode();
      let center = locode.coordinates;
      let bearing = locode.bearing;
      let zoom = locode.zoom;

      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        //fpsOnInteracting: 0,
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        doubleClickZoom: false,
        baseLayer: new maptalks.TileLayer("baselayer", {
          visible: true,
          urlTemplate: configs.getUrlRaster(),
          subdomains: ["a", "b", "c", "d"],
          attribution: "OSM CARTO",
        }),
      });

      this.threeLayer = new ThreeLayer("threeLayer", {
        forceRenderOnRotating: true,
        forceRenderOnMoving: true,
        forceRenderOnZooming: true,
        animation: true,
      });

      // the shipsLayer to draw ships
      this.shipsLayer = new ThreeLayer("ships", {
        identifyCountOnEvent: 1,
        forceRenderOnMoving: false,
        forceRenderOnRotating: false,
        // animation: true
      });
    },

    goToHome() {
      this.map.setView({
        center: configs.getCenter(),
        zoom: 16,
        pitch: 0,
        bearing: 0,
      });
    },
    animate() {
      requestAnimationFrame(this.animate.bind(this));
      this.threeLayer.loop();
      this.threeLayer._needsUpdate = !this.threeLayer._needsUpdate;
      if (this.threeLayer._needsUpdate && !this.threeLayer.isRendering()) {
        this.threeLayer.redraw();
      }
    },
    animateShipsLayer() {
      requestAnimationFrame(this.animateShipsLayer.bind(this));
      this.shipsLayer.loop();
      this.shipsLayer._needsUpdate = !this.shipsLayer._needsUpdate;
      if (this.shipsLayer._needsUpdate && !this.shipsLayer.isRendering()) {
        this.shipsLayer.redraw();
      }
    },
    goTo({ coordinates, zoomLevel }) {
      this.map.setView({
        center: coordinates,
        zoom: zoomLevel,
        pitch: 0,
        bearing: 0,
      });
    },
  },
};
</script>

<style>
.map,
.map-container {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
}

.legend {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 250px;
  max-height: calc(100% - 100px);
  min-height: 150px;
  background: rgba(255, 255, 255, 0.8);
}

.v-list-item-avatar > div {
  border-radius: 0;
}
</style>
