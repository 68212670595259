export const segregations = [
  {
    code: -1,
    color: "#FD8A8A",
    label: "Com IMDG, mas Ok",
  },
  {
    code: 0,
    color: "#e0f0e3",
    label: "Sem IMDG",
  },
  {
    code: 2,
    color: "#FFBA00",
    label: "Segregar a mais de 3M",
  },
  {
    code: 3,
    color: "#e65100",
    label: "Segregar a mais de 5M",
  },
  {
    code: 4,
    color: "#c62828",
    label: "Isolar",
  },
  {
    code: 5,
    color: "#616161",
    label: "Validar",
  },
  {
    code: 6,
    color: "#000000",
    label: "Separar",
  },
];
