<template>
    <div class="ma-4">
        <div class="d-flex flex-wrap">
            <div class="ma-0 pa-0">
                <p class="text-h5 font-weight-black mb-0">
                    {{ $t("configurations.segregations") }}
                </p>
                <p class="text-caption">
                    {{ $t("configurations.segregations_information") }}
                </p>
            </div>
            <v-spacer></v-spacer>

            <div class="float-right">
                <v-btn depressed v-if="hasUserPermissionToViewEditOrManage('SEGREGATIONS')" class="mb-5 mr-3"
                    color="primary" @click="add">
                    <v-icon left>mdi-plus</v-icon>
                    {{ $t("global.create_segregation") }}
                </v-btn>
                <v-btn depressed v-if="hasUserPermissionToViewEditOrManage('SEGREGATIONS')" class="mb-5" color="primary"
                    @click="importGeoJSON">
                    <v-icon left>mdi-plus-box-multiple</v-icon>
                    {{ $t("global.import_segregation") }}
                </v-btn>
            </div>
        </div>

        <v-row class="fill-height">
            <v-col style="min-height: 50vh" cols="12" sm="12" md="12" lg="12">
                <List @remove="remove" @edit="edit" :key="componentKey" :selected.sync="selectedSegregation" />
            </v-col>
        </v-row>
        <ImportSegregationDialog :open.sync="openImportSegregationDialog" />
        <CreateSegregationDialog :open.sync="openAddSegregationDialog" />
        <EditSegregationDialog :open.sync="openEditSegregationDialog" :item="selectedSegregation" />
        <DeleteSegregationDialog :open.sync="openDeleteSegregationDialog" :item="selectedSegregation" />
    </div>
</template>

<script>
import List from "@/components/Configurations/Segregations/List";
import CreateSegregationDialog from "@/components/Configurations/Segregations/CreateSegregationDialog";
import EditSegregationDialog from "@/components/Configurations/Segregations/EditSegregationDialog";
import DeleteSegregationDialog from "@/components/Configurations/Segregations/DeleteSegregationDialog";
import ImportSegregationDialog from "@/components/Configurations/Segregations/ImportSegregationDialog";
export default {
    components: {
        List,
        CreateSegregationDialog,
        EditSegregationDialog,
        DeleteSegregationDialog,
        ImportSegregationDialog,
    },
    data() {
        return {
            componentKey: 0,
            openAddSegregationDialog: false,
            openEditSegregationDialog: false,
            openDeleteSegregationDialog: false,
            openImportSegregationDialog: false,
            selectedSegregation: [],
        };
    },
    watch: {
        openAddSegregationDialog(val) {
            if (val == false) this.forceRerender();
        },
        openEditSegregationDialog(val) {
            if (val == false) this.forceRerender();
        },
        openDeleteSegregationDialog(val) {
            if (val == false) this.forceRerender();
        },
        openImportSegregationDialog(val) {
            if (val == false) this.forceRerender();
        },
    },
    methods: {
        add() {
            this.openAddSegregationDialog = true;
        },
        edit(segregation) {
            this.selectedSegregation = segregation;
            this.openEditSegregationDialog = true;
        },
        remove(segregation) {
            this.selectedSegregation = segregation;
            this.openDeleteSegregationDialog = true;
        },
        importGeoJSON() {
            this.openImportSegregationDialog = true;
        },
        forceRerender() {
            this.componentKey += 1;
        },
    },
};
</script>