<template></template>

<script>
import * as THREE from "three";
import * as maptalks from "maptalks";
import { BaseObject } from "maptalks.three";
import { GeoDataSet } from "geojsondataset";

var OPTIONS = {
  altitude: 0
};

//https://zhuanlan.zhihu.com/p/199353080
class OutLine extends BaseObject {
  constructor(mesh, options, material, layer) {
    options = maptalks.Util.extend({}, OPTIONS, options, { layer });
    super();
    //Initialize internal configuration
    // https://github.com/maptalks/maptalks.three/blob/1e45f5238f500225ada1deb09b8bab18c1b52cf2/src/BaseObject.js#L135
    this._initOptions(options);

    const edges = new THREE.EdgesGeometry(mesh.getObject3d().geometry, 1);
    const lineS = new THREE.LineSegments(edges, material);
    this._createGroup();
    this.getObject3d().add(lineS);
    //Initialize internal object3d
    // https://github.com/maptalks/maptalks.three/blob/1e45f5238f500225ada1deb09b8bab18c1b52cf2/src/BaseObject.js#L140

    //set object3d position
    this.getObject3d().position.copy(mesh.getObject3d().position);
  }
}
const material = new THREE.MeshPhongMaterial({
  // map: texture,
  transparent: false,
  color: 'rgb(0,0,0)'
});

const lineMaterial = new THREE.LineBasicMaterial({
  color: "whitesmoke",
  transparent: false,
  linewidth: 10,
});
export default {
  props: ["refMap", "refThreeLayer"],
  data: () => ({
    geoDataSet: null,
    dataGroupId: 'cells'
  }),
  mounted() {
    this.initScene();
  },
  methods:
  {
    loadParks() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("cells/GET_CELLS_GEOJSON")
          .then((geojson) => {
            this.geoDataSet.addGeoData(this.dataGroupId, geojson);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    initScene() {
      this.geoDataSet = new GeoDataSet({ coordinateCount: 1000, loopFPS: 2 });

      this.geoDataSet.on(this.dataGroupId, (geojson) => {

        let cells = geojson.map(element => {
          const f = JSON.parse(element.feature);
          const polygon = maptalks.GeoJSON.toGeometry(f);

          polygon.setProperties({
            height: 0.01,
            bottomHeight: 0.001,
          });
          return polygon;
        });

        const mesh = this.threeLayer.toExtrudePolygons(cells.slice(0, Infinity), { interactive: false, topColor: '#fff' }, material);
        const meshCells = new OutLine(mesh, { interactive: false }, lineMaterial, this.threeLayer);
        this.threeLayer.delayAddMesh(meshCells);


      })

      this.loadParks();
    }
  },
  computed: {
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },

    threeLayer: {
      get() {
        return this.refThreeLayer;
      },
      set(val) {
        this.$emit("update:refThreeLayer", val);
      },
    },
  },
};
</script>