<template>
    <v-dialog v-model="isOpen" persistent width="35vw">
        <v-card tile>
            <v-expand-transition>
                <div v-show="expand">
                    <v-alert class="mb-0" tile type="error" @click="expand = false">
                        <div v-for="(message, index) in this.messages" :key="index" class="subtitle">
                            {{ message }}
                        </div>
                    </v-alert>
                </div>
            </v-expand-transition>
            <v-progress-linear height="10" striped color="lime" indeterminate v-show="loading"></v-progress-linear>
            <v-toolbar dark color="primary" flat>
                <v-toolbar-title>
                    <span>
                        <v-icon left>mdi-pencil</v-icon>{{ $t("global.edit_segregation") }}
                    </span>
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon dark @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
                    <v-row>
                        <v-text-field counter="7" maxlength="7" v-model="itemToEdit.color" required
                            :label="$t('global.color')" :rules="hexColorRules" outlined
                            @input="prependHash"></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field v-model="itemToEdit.label" required :label="$t('global.label')" :rules="nameRules"
                            outlined></v-text-field>

                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="save" :disabled="loading">
                    {{ $t("global.update") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["open", "item"],
    data() {
        return {
            loading: false,
            expand: false,
            messages: [],
            nameRules: [
                (v) => !!v || this.$t("rules.required_field"),
                (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
            ],
            hexColorRules: [
                v => !!v || 'Color is required',
                v => /^#[0-9A-Fa-f]{6}$/i.test(v) || this.$t("rules.invalid_color"),
            ],
        };
    },
    computed: {
        isOpen: {
            get() {
                return this.open;
            },
            set(val) {
                this.$emit("update:open", val);
            },
        },
        itemToEdit: {
            get() {
                return JSON.parse(JSON.stringify(this.item));
            },

            set(val) {
                this.$emit("update:item", val);
            },
        },
    },
    methods: {
        prependHash(value) {
            if (!value.startsWith('#')) {
                this.itemToEdit.color = '#' + value.replace(/[^0-9A-Fa-f]/g, '');
            } else {
                this.itemToEdit.color = value.replace(/[^#0-9A-Fa-f]/g, '');
            }
        },
        close() {
            this.isOpen = false;
            this.expand = false;
            this.loading = false;
        },
        save() {
            this.expand = false;
            this.loading = true;
            this.messages = [];
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch("UPDATE_SEGREGATIONS", this.itemToEdit)
                    .then((res) => {
                        if (res.errors) {
                            Object.entries(res.errors).forEach(([, value]) => {
                                this.messages = this.messages.concat(value);
                            });
                            this.expand = true;
                            this.loading = false;
                        } else {
                            this.close();
                        }
                    });
            } else {
                this.expand = false;
                this.loading = false;
            }
        },
    },
};
</script>