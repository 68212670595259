class DispatcherEvent {
  constructor(eventName) {
    this.eventName = eventName;
    this.callbacks = [];
  }

  registerCallback(callback) {
    this.callbacks.push(callback);
  }

  unregisterCallback(callback) {
    const index = this.callbacks.indexOf(callback);
    if (index > -1) {
      this.callbacks.splice(index, 1);
    }
  }

  fire(data) {
    const callbacks = this.callbacks.slice(0);
    callbacks.forEach((callback) => {
      callback(data);
    });
  }
}

const LOOP_TIMEOUT = 200; //ms
const NUMBER_OF_ACTIONS_TO_PERFORM = 5000; //X actions per LOOP_TIMEOUT
const DISPATCH_ACTION_DURATION = LOOP_TIMEOUT / NUMBER_OF_ACTIONS_TO_PERFORM; //ms

class Tracker {
  constructor() {
    this.events = {};
    this.records = new Map();
    this.actions = [];
    this.isRunning = false;
  }

  start() {
    this.clear();
    if (this._loopTimeout) {
      clearTimeout(this._loopTimeout);
    }
    this.isRunning = true;
    this.runLoop();
  }

  stop() {
    this.clear();
    if (this._loopTimeout) {
      clearTimeout(this._loopTimeout);
    }
    this.isRunning = false;
  }

  clear() {
    this.records.clear();
    this.actions = [];
  }

  async runLoop() {
    if (this._loopTimeout) {
      clearTimeout(this._loopTimeout);
    }
    if (!this.isRunning) {
      return;
    }
    //run here some action dispatching
    let actionsToPerform = this.actions.splice(0, NUMBER_OF_ACTIONS_TO_PERFORM);
    for (let i = 0; i < actionsToPerform.length; i++) {
      await this.dispatchActionToPerform(actionsToPerform[i]);
    }
    this._loopTimeout = setTimeout(this.runLoop.bind(this), LOOP_TIMEOUT);
  }

  dispatchActionToPerform(action) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.dispatch(action.operation, action.record);
        resolve();
      }, DISPATCH_ACTION_DURATION);
    });
  }

  getAll() {
    return this.records;
  }

  exists(id) {
    return this.records.has(id);
  }

  add(record) {
    if (this.exists(record.id)) {
      this.update(record);
    } else {
      this.records.set(record.id, record);
      this.actions.push({
        operation: "add",
        id: record.id,
        record: record,
      });
    }
  }

  remove(record) {
    if (this.exists(record.id)) {
      let recordObj = this.records.get(record.id);
      this.actions.push({
        operation: "remove",
        id: record.id,
        record: recordObj,
      });
      this.records.delete(record.id);
    }
  }

  update(record) {
    if (this.exists(record.id)) {
      let recordToUpdate = this.records.get(record.id);
      recordToUpdate = Object.assign(recordToUpdate, record);
      this.records.set(record.id, recordToUpdate);
      this.actions.push({
        operation: "update",
        id: record.id,
        record: recordToUpdate,
      });
    } else {
      this.add(record);
    }
  }

  dispatch(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.fire(data);
    }
  }

  on(eventName, callback) {
    let event = this.events[eventName];
    if (!event) {
      event = new DispatcherEvent(eventName);
      this.events[eventName] = event;
    }
    event.registerCallback(callback);
  }

  off(eventName, callback) {
    const event = this.events[eventName];
    if (event && event.callbacks.indexOf(callback) > -1) {
      event.unregisterCallback(callback);
      if (event.callbacks.length === 0) {
        delete this.events[eventName];
      }
    }
  }
}
export default Tracker;
