import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const containersService = {
  getContainers,
  getGeoJson,
  getContainerInfoByBic,
};

async function getContainers() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/containers", requestOptions);
}

async function getGeoJson() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/containers/geojson", requestOptions);
}

async function getContainerInfoByBic(bic) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/containers/" + bic, requestOptions);
}