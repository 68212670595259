import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const imdgsService = {
  getImdgs,
  updateImdg,
  deleteImdg,
  createImdg,
  importImdgs,
};

async function getImdgs() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/imdgs", requestOptions);
}

async function updateImdg(imdg) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/imdgs/" + imdg.id,
    imdg,
    requestOptions
  );
}

async function deleteImdg(id) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/imdgs/" + id,
    requestOptions
  );
}

async function createImdg(imdg) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };

  return await axios.post(
    configs.getApiUrl() + "/v1/imdgs",
    imdg,
    requestOptions
  );
}

async function importImdgs(file) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/imdgs/import",
    bodyFormData,
    requestOptions
  );
  return res;
}
