<template>
    <Users />
</template>
  
<script>
import Users from "@/components/Configurations/Users";

export default {
    components: {
        Users,
    },
};
</script>
  