<template>
  <div>
    <v-app-bar app color="primary" dense dark clipped-right clipped-left>
      <a href="#/" rel="noopener">
        <v-img class="mx-1" src="@/assets/logo.png" max-height="40" max-width="40" contain></v-img>
      </a>

      <v-toolbar-title v-if="!$vuetify.breakpoint.xs">
        <v-list-item>
          <v-list-item-content>
            <div>
              <p class="mt-1 mb-1 pa-0" style="font-size: 18px">
                <b>3Park</b>
              </p>
              <p class="mt-1 mb-1 pa-0" style="font-size: 12px">
                {{ locode.name }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <Weather class="pa-1 mt-0" v-if="!$vuetify.breakpoint.xs" />

      <v-divider vertical v-if="isMap"></v-divider>

      <v-switch v-model="parksOpen" inset label="Mostrar Parques" class="pa-4 mt-6" v-if="isMap"></v-switch>

      <v-divider vertical></v-divider>

      <v-app-bar-nav-icon @click.stop="menuOpen = !menuOpen"></v-app-bar-nav-icon>

    </v-app-bar>

    <v-navigation-drawer disable-resize-watcher app clipped left v-model="infoOpen"
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }" :width="400" v-if="isMap">
      <Info :bic.sync="selectedBic" />
    </v-navigation-drawer>

    <v-navigation-drawer disable-resize-watcher temporary v-model="menuOpen" app clipped right
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }" :width="400">
      <Menu />
    </v-navigation-drawer>

    <v-navigation-drawer disable-resize-watcher v-model="parksOpen" app clipped right
      :style="{ top: $vuetify.application.top + 'px', zIndex: 2 }" :width="400" :permanent="parksOpen" v-if="isMap">
      <ParksList v-if="parksOpen" />
    </v-navigation-drawer>

  </div>
</template>

<script>
import Menu from "@/components/Main/Menu";
import Weather from "@/components/Main/Weather";
import ParksList from "@/components/Parks/List";
import Info from "@/components/Containers/Info";

import configs from "@/helpers/configs";

export default {
  components: {
    Weather,
    ParksList,
    Menu,
    Info,
  },
  computed: {
    isMap() {
      return this.$route.name.toUpperCase() == "MAP";
    },
    locode() {
      return configs.getLocode();
    },
    infoOpen: {
      get() {
        return !!this.selectedBic;
      },
      set(val) {
        this.$emit("update:bic", val);
      },
    }
  },
  data() {
    return {
      menuOpen: false,
      parksOpen: false,
      selectedBic: null
    };
  },
  mounted() {
    this.$root.$on('showContainer', this.showContainer);
  },
  beforeDestroy() {
    this.$root.$off('showContainer', this.showContainer);
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) return;
      this.menuOpen = false;
    }
  },
  methods:
  {
    showContainer(val) {
      this.selectedBic = val;
    }
  }
};
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
  background-color: white !important;
}
</style>

<style scoped>
.main-content {
  height: calc(100vh - var(--toolbar-height));
  position: relative;
  overflow: hidden;
}

.pane-content {
  height: calc(100vh - var(--toolbar-height));
}
</style>
