import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "@/locale";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#37474F',
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  lang: {
    /*locales,
    current: settings.locale,*/
    t: (key, ...params) => i18n.t(key, params),
  },
});

export default vuetify;
