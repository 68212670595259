import {
  sharedHelpers
} from "@/api_services/utils/shared-helpers";

const VIEW_PERMISSION = 'VIEW';
const VIEW_EDIT_PERMISSION = "VIEW_EDIT";
const MANAGE_PERMISSION = 'MANAGE';

function userHasPermissionFor(resource, permission) {
  let userPermissions = sharedHelpers.getUserPermissions();
  if (userPermissions && userPermissions.length > 0) {
    return userPermissions.findIndex((p) => p.code == resource && p.profile == permission) !== -1;
  }
}

export default {
  //  install
  install(Vue) {
    Vue.prototype.hasUserPermissionToView = (resource) => {
      return userHasPermissionFor(resource, VIEW_PERMISSION);
    };
    Vue.prototype.hasUserPermissionToViewEdit = (resource) => {
      return userHasPermissionFor(resource, VIEW_EDIT_PERMISSION);
    };
    Vue.prototype.hasUserPermissionToManage = (resource) => {
      return userHasPermissionFor(resource, MANAGE_PERMISSION);
    };
    Vue.prototype.hasUserPermissionToViewOrViewEdit = (resource) => {
      return (
        userHasPermissionFor(resource, VIEW_PERMISSION) ||
        userHasPermissionFor(resource, VIEW_EDIT_PERMISSION)
      );
    };
    Vue.prototype.hasUserPermissionToViewEditOrManage = (resource) => {
      return (
        userHasPermissionFor(resource, VIEW_EDIT_PERMISSION) ||
        userHasPermissionFor(resource, MANAGE_PERMISSION)
      );
    };
    Vue.prototype.hasUserPermissionToViewOrManage = (resource) => {
      return (
        userHasPermissionFor(resource, VIEW_PERMISSION) ||
        userHasPermissionFor(resource, VIEW_EDIT_PERMISSION) ||
        userHasPermissionFor(resource, MANAGE_PERMISSION)
      );
    };
  }
};