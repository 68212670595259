<template>
  <div ref="vscalls" class="vscalls">

    <Stats :open.sync="openStats" :selected.sync="parkStats" v-if="parkStats" :key="componentKey" />

    <v-toolbar color="primary" flat dark dense>
      <v-toolbar-title>
        <v-list-item two-line class="pa-0 ma-0">
          <v-list-item-content>
            <v-list-item-title class="headline">
              Parques ({{ parks.length }})
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text small @click="toggleSearchBar" v-bind="attrs" v-on="on">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span> {{ searchBar ? $t('parks.close_search') : $t('parks.open_search') }}</span>
      </v-tooltip>

    </v-toolbar>

    <v-divider></v-divider>

    <div class="event-card-list">
      <v-text-field v-if="searchBar" v-model="search" class="pa-2" :placeholder="$t('parks.search')" filled outlined dense
        hide-details>
      </v-text-field>
      <v-progress-linear indeterminate color="yellow darken-2" v-if="loading"></v-progress-linear>
      <v-data-iterator :items="parks" :no-data-text="$t('global.loading_containers')" :items-per-page="parks.length"
        hide-default-footer sortBy="code" :sortDesc="false" :search="search">
        <template v-slot:default="props">
          <v-col>
            <v-row v-for="item in props.items" :key="item.code">
              <v-card width="100%" class="ma-1" outlined elevation="0" :loading="item.loading">
                <v-card-text>
                  <v-row cols="2">
                    <v-col style="display: flex;flex-direction:column;flex:1;">
                      <p class="text-h5 text--primary mb-0">
                        {{ item.code }}
                      </p>
                      <p class="text--primary mb-0" style="margin-top: auto;">
                        {{
      item.containers_last_updated_at
    }}
                      </p>
                    </v-col>

                    <v-col>
                      <v-tooltip color="black" bottom>
                        <template #activator="{ on }">
                          <v-chip label outlined small v-on="on" class="chips">
                            <v-icon small left>mdi-package-variant-closed</v-icon>
                            {{
      !!item.total_containers
        ? item.total_containers
        : 0
    }} Contentores
                          </v-chip>
                        </template>
                        Total de Contentores
                      </v-tooltip>
                      <v-tooltip color="black" bottom>

                        <template #activator="{ on }">
                          <v-chip label outlined small v-on="on" class="chips">
                            <v-icon left small color="green"> mdi-check-decagram-outline</v-icon>

                            {{
      (item.total_containers - item.containers_with_hazmat) || 0
    }} Contentores
                          </v-chip>
                        </template>
                        Contentores sem Hazmat
                      </v-tooltip>
                      <v-tooltip color="black" bottom>

                        <template #activator="{ on }">
                          <v-chip label outlined small v-on="on" class="chips">
                            <v-icon left small color="red"> mdi-alert-outline</v-icon>
                            {{
      item.containers_with_hazmat || 0
    }} Contentores
                          </v-chip>
                        </template>
                        Contentores Hazmat
                      </v-tooltip>
                      <v-tooltip color="black" bottom>

                        <template #activator="{ on }">
                          <v-chip label outlined small v-on="on" class="chips">
                            <v-icon left small color="black"> mdi-map-marker-off</v-icon>
                            {{
      item.containers_without_geom || 0
    }} Contentores
                          </v-chip>
                        </template>
                        Contentores sem célula
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="primary" text @click="goTo(item)" small :disabled="item.loading">
                    {{ $t("global.map") }}
                  </v-btn>
                  <v-btn color="red darken-4" text @click="syncPark(item)" small :disabled="item.loading">
                    {{ $t("global.update") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="black dark-4" text @click="showStats(item)" small v-if="item.total_containers"
                    :disabled="item.loading">
                    {{ $t("global.stats") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-col>
        </template>

        <template v-slot:no-results>
          <div class="subtitle-1 text-center">{{ $t('global.no_data') }}</div>
        </template>

        <template v-slot:no-data>
          <span style="margin-left:130px">{{ $t('global.loading_parks') }}</span>
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<script>
import Stats from "../Parks/Stats";
import * as maptalks from "maptalks";

export default {
  props: ["open", "selected"],
  components: {
    Stats,
  },
  data() {
    return {
      loading: true,
      openStats: false,
      parkStats: null,
      componentKey: 0,
      searchBar: false,
      search: "",
    };
  },
  mounted() {
    this.getParks();
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    selectedPark: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
    parks() {
      return this.$store.state.parks.list;
    },
  },
  methods: {
    toggleSearchBar() {
      this.searchBar = !this.searchBar;
    },
    getParks() {
      this.loading = true;
      this.$store.dispatch("parks/GET_PARKS").then(() => {
        this.loading = false;
      });
    },
    goTo(park) {
      this.selectedPark = park;
      let parkCoords = new maptalks.Polygon(JSON.parse(park.feature).geometry.coordinates).getCenter();
      this.$root.$emit('goTo', { coordinates: parkCoords, zoomLevel: 18 });
      this.$root.$emit('highlightPark', park.code);
    },
    syncPark(park) {
      this.$store.dispatch("parks/REFRESH", park).then(() => {
        this.$root.$emit('reloadContainers');
      });
    },
    showStats(park) {
      this.openStats = true;
      this.parkStats = park;
      this.componentKey += 1;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style>
.v-navigation-drawer__content {
  overflow-y: hidden !important;
}

.chips {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}
</style>

<style scoped>
/* Event Card List CSS */
.event-card-list {
  flex-grow: 1;
  overflow: auto;
  height: calc(100% - var(--toolbar-height));
}

.event-card-list-item {
  padding: 3px 2px;
}

.event-card {
  overflow: hidden;
  width: 100%;
  border-radius: 0px;
  border: 2px solid white;
  background-color: transparent !important;
}

.event-card .name {
  font-size: 1.1em;
  font-weight: 400;
}

.event-card .process_number {
  font-size: 0.7em;
}

.event-card .type,
.event-card .etd,
.event-card .eta {
  font-size: 0.7em;
  font-weight: 400;
  color: #6d6d6d;
}

.event-card .status,
.event-card .tag {
  width: 100%;
}

.event-card .tag i {
  font-size: 1.3em;
  margin-bottom: 0.1em;
}

.event-card .updated_at {
  font-size: 0.7em;
  font-weight: 400;
  color: #021727;
  margin: 5px;
  margin-left: 5px;
}

.vscalls {
  height: calc(100%);
  overflow: hidden;
}
</style>