import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Vuex from "vuex";
import VueRouter from "vue-router";
import router from "./router";
import VCalendar from "v-calendar";
import store from "./store";
import axios from "axios";

import CountryFlag from "vue-country-flag";

// Constants
import Constants from "@/plugins/constants";

//Permission checker
import Permissions from "@/plugins/permissions";

//fonts used
import "roboto-fontface/css/roboto/roboto-fontface.css";

import i18n from "@/locale";

// Filters
import "@/filters";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Constants);
Vue.use(Permissions);
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
Vue.component("country-flag", CountryFlag);

const app = new Vue({
  vuetify,
  router,
  store,
  axios,
  i18n,
  render: (h) => h(App),
});

// Add the Axios interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      app.$store.commit("setShowSessionErr", true);
      app.$router.push("/login", () => { });
    }
    return Promise.reject(error);
  }
);

app.$mount("#app");
