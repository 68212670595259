<template>
  <div class="ma-5">
    <v-data-table v-model="selectedCell" :loading="loading" :search="searchTerm" :page.sync="pageIndex"
      :headers="headers" :items="items" single-select @click:row="rowClick" item-key="id" fixed-header sort-by="code"
      must-sort height="calc(100vh - 330px)" :items-per-page="15" :footer-props="{ 'show-first-last-page': true }">
      <template v-slot:top>
        <v-text-field v-model="searchTerm" single-line :label="$t('global.search_cell')"
          prepend-inner-icon="mdi-magnify" outlined />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu v-if="hasUserPermissionToViewEditOrManage('CELLS')" bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="hasUserPermissionToManage('CELLS')" @click="remove(item)">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Remover</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hasUserPermissionToViewEditOrManage('CELLS')" @click="edit(item)">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["selected", "search", "page"],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Nome",
          value: "name",
        }
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.cells.list;
    },
    searchTerm: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit("update:search", val);
      },
    },
    pageIndex: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    selectedCell: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  watch:
  {
    selectedCell() {
      this.$nextTick(() => {
        let elem = document.querySelector(".v-data-table__selected");
        if (elem) elem.scrollIntoView({ block: "end", behavior: "smooth" });
      });
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("cells/GET_CELLS").then(() => {
      this.loading = false;
    });
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },

    rowClick: function (item, row) {
      row.select(true);
    },
  },
};
</script>

<style>
tr.v-data-table__selected,
tr.v-data-table__selected:hover {
  background: yellow !important;
}
</style>