import io from "socket.io-client";
import configs from "@/helpers/configs";
import ContainersTracker from "@/helpers/realtime/containerstracker";
import AISShipsTracker from "@/helpers/realtime/aisshipstracker";
const URL = configs.getRealtimeUrl();
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

//The channels to subscribe for realtime updates
const CONTAINERS_CHANNEL = "containers";
const AIS_SHIPS_CHANNEL = "ais_ships";
export default class SocketServiceHelper {
  static getChannelInstance(channel) {
    let instance;
    switch (channel) {
      case CONTAINERS_CHANNEL:
        instance = ContainersTracker;
        break;
      case AIS_SHIPS_CHANNEL:
        instance = AISShipsTracker;
        break;
    }
    return instance;
  }

  static initializeChannel(channel) {
    SocketServiceHelper.deInitializeChannel(channel);
    if (SocketServiceHelper._connection) {
      let instance = SocketServiceHelper.getChannelInstance(channel);
      if (instance) {
        instance.clear();
        instance.start();
      }
      SocketServiceHelper._connection.emit("subscribeChannel", {
        channel: channel,
      });
      SocketServiceHelper._connection.on(channel, (message) => {
        let instance = SocketServiceHelper.getChannelInstance(channel);
        if (message) {
          if (Array.isArray(message)) {
            message.forEach((msg) => {
              if (msg.new_val && !msg.old_val) {
                instance.add(msg.new_val);
              } else if (msg.new_val && msg.old_val) {
                instance.update(msg.new_val);
              } else if (!msg.new_val && msg.old_val) {
                instance.remove(msg.old_val);
              }
            });
          } else {
            if (message.new_val && !message.old_val) {
              instance.add(message.new_val);
            } else if (message.new_val && message.old_val) {
              instance.update(message.new_val);
            } else if (!message.new_val && message.old_val) {
              instance.remove(message.old_val);
            }
          }
        }
      });
    }
  }

  static deInitializeChannel(channel) {
    if (SocketServiceHelper._connection) {
      SocketServiceHelper._connection.emit("unsubscribeChannel", {
        channel: channel,
      });
      SocketServiceHelper._connection.off(channel);
      let instance = SocketServiceHelper.getChannelInstance(channel);
      if (instance) {
        instance.stop();
        instance.clear();
      }
    }
  }

  static deInitialize() {
    if (SocketServiceHelper._connection) {
      SocketServiceHelper._connection.off("connect");
      SocketServiceHelper._connection.off("disconnect");
      SocketServiceHelper._connection.off("error");
      SocketServiceHelper._connection.off(AIS_SHIPS_CHANNEL);
      SocketServiceHelper._connection.off(CONTAINERS_CHANNEL);
      SocketServiceHelper._connection.disconnect();
      SocketServiceHelper._connection = null;
    }
  }

  static initialize() {
    SocketServiceHelper.deInitialize();
    if (!SocketServiceHelper._connection) {
      SocketServiceHelper._connection = io(URL, {
        autoConnect: true,
        forceNew: true,
        reconnectionDelayMax: 5000,
        maxReconnectionAttempts: Infinity,
        auth: {
          token: sharedHelpers.getAuthToken(),
        },
        extraHeaders: sharedHelpers.getRequestHeaders(),
      });

      SocketServiceHelper._connection.on("connect", () => {
        SocketServiceHelper.initializeChannel(AIS_SHIPS_CHANNEL);
        SocketServiceHelper.initializeChannel(CONTAINERS_CHANNEL);
      });

      SocketServiceHelper._connection.on("disconnect", () => {
        SocketServiceHelper.deInitializeChannel(AIS_SHIPS_CHANNEL);
        SocketServiceHelper.deInitializeChannel(CONTAINERS_CHANNEL);
      });
    }
  }
}
