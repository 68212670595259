<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.types") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.types_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div class="float-right" v-if="hasUserPermissionToViewEditOrManage('TYPES')">
        <v-btn depressed class="mb-5" color="primary" @click="add">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("global.create_type") }}
        </v-btn>
        <v-btn depressed class="mb-5 ml-1" color="primary" @click="importGeoJSON">
          <v-icon left>mdi-database-import</v-icon>
          {{ $t("global.import_types") }}
        </v-btn>
      </div>
    </div>
    <List @remove="remove" @edit="edit" />
    <CreateTypeDialog :open.sync="openAddTypeDialog" />
    <EditTypeDialog :open.sync="openEditTypeDialog" :item="selectedType" />
    <DeleteTypeDialog :open.sync="openDeleteTypeDialog" :item="selectedType" />
    <ImportTypeDialog :open.sync="openImportTypeDialog" />
  </div>
</template>

<script>
import List from "./Types/List";
import CreateTypeDialog from "./Types/CreateTypeDialog";
import EditTypeDialog from "./Types/EditTypeDialog";
import DeleteTypeDialog from "./Types/DeleteTypeDialog";
import ImportTypeDialog from "./Types/ImportTypeDialog";
export default {
  components: {
    List,
    CreateTypeDialog,
    EditTypeDialog,
    DeleteTypeDialog,
    ImportTypeDialog,
  },
  data() {
    return {
      openAddTypeDialog: false,
      openEditTypeDialog: false,
      openDeleteTypeDialog: false,
      openImportTypeDialog: false,
      selectedType: null,
    };
  },
  methods: {
    add() {
      this.openAddTypeDialog = true;
    },
    edit(type) {
      this.selectedType = type;
      this.openEditTypeDialog = true;
    },
    remove(type) {
      this.selectedType = type;
      this.openDeleteTypeDialog = true;
    },
    importGeoJSON() {
      this.openImportTypeDialog = true;
    },
  },
};
</script>
