import locodes from "@/locodes.json";
import store from "@/store/index";

const DEFAULT_PERMISSIONS = [
  {
    code: "MAP",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-map"
  },
  {
    code: "PARKS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-alpha-p-box-outline"
  },
  {
    code: "CONTAINERS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-cube-outline"
  },
  {
    code: "CELLS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-border-all"
  },
  {
    code: "TYPES",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-square-opacity"
  },
  {
    code: "OPERATORS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-card-bulleted-outline"
  },
  {
    code: "IMDGS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-hazard-lights"
  },
  {
    code: "SEGREGATIONS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-alert-octagram-outline"
  },
  {
    code: "USERS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-account-group"
  }
  
];

export default {
  getMeterProjection() {
    return store.state.user.account.locode &&
      store.state.user.account.locode.meter_projection
      ? store.state.user.account.locode.meter_projection
      : `+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs`;
  },

  getDefaultPermissions() {
    return DEFAULT_PERMISSIONS;
  },

  getAdminUserType() {
    return "ROOT";
  },

  getManagerUserType() {
    return "USER";
  },

  getLocode() {
    return store.state.user.account.locode;
  },

  getLocale() {
    return store.state.user.account.locode
      ? store.state.user.account.locode.locale
      : "pt-PT";
  },

  getTimezone() {
    return store.state.user.account.locode
      ? store.state.user.account.locode.timezone
      : "Europe/London";
  },

  getLocodes() {
    let vue_app_locodes = window.VUE_APP_LOCODES
      ? window.VUE_APP_LOCODES.split(",")
      : [];
    if (vue_app_locodes.length > 0) {
      return locodes.filter((locode) => {
        return vue_app_locodes.indexOf(locode.code) !== -1;
      });
    } else return locodes;
  },

  getUrlRaster() {
    let url = "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png";

    if (window.VUE_APP_DEFAULT_URL_RASTER)
      url = window.VUE_APP_DEFAULT_URL_RASTER;

    return url;
  },

  getApiUrl() {
    return process.env.VUE_APP_API_URL;
  },

  getApiImagesUrl() {
    return process.env.VUE_APP_API_IMAGES_URL;
  },

  getRealtimeUrl() {
    return process.env.VUE_APP_REALTIME_URL;
  },

  getCenter() {
    let coords = [0, 0];
    let locode = this.getLocode();
    if (window.VUE_APP_DEFAULT_LOCODE && locode) coords = locode.coordinates;
    if (window.VUE_APP_DEFAULT_LONGITUDE && window.VUE_APP_DEFAULT_LATITUDE)
      coords = [
        window.VUE_APP_DEFAULT_LONGITUDE,
        window.VUE_APP_DEFAULT_LATITUDE,
      ];
    return coords;
  },
};
