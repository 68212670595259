<template>
  <div class="map-container">
    <div ref="map" class="map" />
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import * as maptalks from "maptalks";
const PARKS_LAYERNAME = "parks";
export default {
  props: ["selected"],
  data() {
    return {
      map: null,
      loading: false,
      parksHighlightSymbol: {
        lineWidth: 5,
        lineColor: "yellow",
        polygonOpacity: 0.2,
      },
      parksSymbol: {
        lineColor: "#34495e",
        lineWidth: 1,
        polygonFill: "rgb(231,209, 92)",
        polygonOpacity: 0.6,
        textFaceName: "Roboto",
        textName: "{code}", //value from name in geometry's properties
        textWeight: "bold", //'bold', 'bold'
        textStyle: "normal", //'italic', 'oblique'
        textSize: {
          stops: [
            [15, 0],
            [16, 12],
          ],
        },
        textOpacity: 1,
        textLineSpacing: 0,
        textDx: 0,
        textDy: 0,
        textHorizontalAlignment: "middle", //left | middle | right | auto
        textVerticalAlignment: "middle", // top | middle | bottom | auto
        textAlign: "center", //left | right | center | auto,
        textFill: "#34495e",
      },
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
    parks() {
      return this.$store.state.parks.geojson;
    },
    selectedPark: {
      get() {
        return this.selected[0];
      },
      set(val) {
        this.$emit("update:selected", [val]);
      },
    },
  },
  watch: {
    parks() {
      this.processParks();
    },
    selectedPark(val) {
      if (val) this.selectPark(val);
    }
  },
  mounted() {
    this.initMap();
    this.loadParks();
  },
  beforeDestroy() {
    this.map.off("click", this.handleMapClick);
  },
  methods: {
    initMap() {
      let center;
      let bearing;
      let zoom;
      try {
        center = this.locode.coordinates;
        bearing = this.locode.bearing;
        zoom = this.locode.zoom;
      } catch (e) {
        console.error(e);
        center = [-8.702487, 41.183199];
        bearing = -38;
        zoom = 15;
      }
      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        layerSwitcherControl: false,
        baseLayer: new maptalks.TileLayer("tile", {
          urlTemplate:
            "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png",
          subdomains: ["a", "b", "c", "d"],
          attribution: "",
        }),
        //]),
        layers: [new maptalks.VectorLayer(PARKS_LAYERNAME, [])],
      });
      this.map.on("click", this.handleMapClick);
    },
    loadParks() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("parks/GET_PARKS_GEOJSON")
          .then(() => {
            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
    selectPark(park) {
      if (park && park.feature) {
        let polygon = new maptalks.Polygon(JSON.parse(park.feature).geometry.coordinates);

        this.map.fitExtent(polygon.getExtent(), 0);

        this.map
          .getLayer(PARKS_LAYERNAME)
          .forEach((f) => f.properties.id == park.id ? f.updateSymbol(this.parksHighlightSymbol) : f.setSymbol(this.parksSymbol));
      }

    },
    processParks() {
      if (this.map) {
        let layer = this.map.getLayer(PARKS_LAYERNAME);
        layer.clear();
        if (this.parks) {
          this.parks.forEach((park) => {
            let geometry = maptalks.GeoJSON.toGeometry(park.feature);
            if (geometry) {
              let data = JSON.parse(park.feature);
              geometry.setSymbol(this.parksSymbol);
              geometry.setProperties({
                id: data.properties.id,
                code: data.properties.code,
                name: data.type,
              });
              geometry.addTo(layer);
              geometry.on("click", this.handleParkClicked);
            }
          });
        }
      }
    },
    deSelectParks() {
      if (this.map) {
        this.map
          .getLayer(PARKS_LAYERNAME)
          .forEach((l) => l.setSymbol(this.parksSymbol));
      }
      this.selectedPark = null;
    },
    handleParkClicked(e) {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      this.deSelectParks();
      if (e.target) {
        e.target.updateSymbol(this.parksHighlightSymbol);
        let found = this.parks.find((z) => {
          return z.id == e.target.properties.id;
        });
        if (found) {
          this.selectedPark = found;
        }
      }
    },
    handleMapClick() {
      this.deSelectParks();
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
}
</style>

<style scoped src="@/../node_modules/maptalks/dist/maptalks.css"></style>
