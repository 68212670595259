<template>
  <v-card class="overflow-x-hidden" elevation="0" tile>
    <v-toolbar
      :color="container ? (container.hazmats ? 'red' : 'green') : 'primary'"
      flat
      dark
      dense
    >
      <v-toolbar-title>
        <v-list-item class="pa-0 ma-0">
          <v-list-item-content>
            <v-list-item-title class="headline font-weight-black">
              {{ selectedBic }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text
      style="height: calc(100vh - 96px); overflow-y: auto"
      class="pa-0 px-2"
    >
      <div style="position: relative; height: 100%" v-if="loading">
        <Loading :value="loading"></Loading>
      </div>
      <v-simple-table v-if="!!container && !loading">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="font-weight-black" width="150px">
                {{ $t("global.park") }}
              </td>
              <td class="text-end">
                {{ container.park }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-black">
                {{ $t("permissions.cells") }}
              </td>
              <td>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="font-weight-medium">
                        {{ $t("global.start") }}:
                      </td>
                      <td class="text-end">
                        {{ container.cell_start }}
                      </td>
                    </tr>
                    <tr v-if="container.cell_end">
                      <td class="font-weight-medium">
                        {{ $t("global.end") }}:
                      </td>
                      <td class="text-end">
                        {{ container.cell_end }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
            <tr>
              <td class="font-weight-black">
                {{ $t("global.level") }}
              </td>
              <td class="text-end">
                {{ container?.data?.level }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-black">Mercadoria Perigosa</td>
              <td class="text-end">
                {{ !!container.hazmats ? "Sim" : "Não" }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-black">
                {{ $t("global.iso_size_type") }}
              </td>
              <td>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-if="container.type_name">
                        <td class="font-weight-bold" colspan="2">
                          {{ container.type_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          {{ $t("global.code") }}:
                        </td>
                        <td class="text-end">
                          {{ container.type ? container.type : "" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          {{ $t("global.height") }}:
                        </td>
                        <td class="text-end">
                          {{ container.type ? container.height : "N/D" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          {{ $t("global.length") }}:
                        </td>
                        <td class="text-end">
                          {{ container.type ? container.length : "N/D" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
            <tr>
              <td class="font-weight-black">
                {{ $t("global.weight") }}
              </td>
              <td>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="font-weight-medium">
                          {{ $t("global.tare_weight") }}:
                        </td>
                        <td class="text-end">
                          {{
                            container.data && container.data.tare_weight
                              ? container.data.tare_weight.toFixed(2)
                              : 0.0
                          }}
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          {{ $t("global.gross_weight") }}:
                        </td>
                        <td class="text-end">
                          {{
                            container.data && container.data.gross_weight
                              ? container.data.gross_weight.toFixed(2)
                              : 0.0
                          }}
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          {{ $t("global.maximum_gross_weight") }}
                        </td>
                        <td class="text-end">
                          {{
                            container.data &&
                            container.data.maximum_gross_weight
                              ? container.data.maximum_gross_weight.toFixed(2)
                              : 0.0
                          }}
                          kg
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
            <tr>
              <td class="font-weight-black">
                {{ $t("global.is_reefer_plugged") }}
              </td>
              <td class="text-end">
                {{
                  container.data &&
                  container.data.reefer_plugged &&
                  container.data.reefer_plugged === true
                    ? $t("global.yes")
                    : $t("global.no")
                }}
              </td>
            </tr>

            <tr v-if="container.complete_hazmats">
              <td class="font-weight-black">
                {{ $t("global.imgd_codes") }}
              </td>
              <td class="text-end">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(hazmat, index) in container.complete_hazmats"
                        :key="index"
                      >
                        <td class="font-weight-medium">
                          {{ hazmat.code }} - {{ hazmat.name }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>

            <tr v-if="container.complete_operator">
              <td class="font-weight-black">
                {{ $t("global.owner") }}
              </td>
              <td class="text-end">
                {{ container.complete_operator.code }} -
                {{ container.complete_operator.name }}
              </td>
            </tr>

            <tr v-if="container.time">
              <td class="font-weight-black">
                {{ $t("global.updated_in") }}
              </td>
              <td class="text-end">
                {{ container.time | formatDatetimeFromDatabase }}
              </td>
            </tr>

            <tr v-if="container.alerts && container.alerts.length > 0">
              <td class="font-weight-black">
                {{ $t("global.segregation_level") }}
              </td>
              <td class="text-end">
                {{ getContainerSegregationLevel(container.alerts) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import listSegregations from "@/assets/segregations/list.json";
import Loading from "@/components/Main/Loading";
import { segregations } from "@/helpers/segregations";
export default {
  props: ["bic"],
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      segregations: listSegregations,
      container: null,
      segregations: segregations,
    };
  },
  computed: {
    selectedBic: {
      get() {
        return this.bic;
      },
      set(val) {
        this.$emit("update:bic", val);
      },
    },
  },
  methods: {
    getContainerSegregationLevel(alerts) {
      try {
        return this.segregations[
          Math.max(...alerts.map((c) => parseFloat(c.code)))
        ].label;
      } catch (ex) {
        console.error("No segregation level found: ", ex);
      }
      return "";
    },

    close() {
      this.selectedBic = null;
    },
    getDetails(bic) {
      this.loading = true;
      this.container = null;
      this.$store
        .dispatch("containers/GET_CONTAINER_INFO_BY_BIC", bic)
        .then((data) => {
          this.container = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    selectedBic(val) {
      if (val) {
        this.getDetails(val);
      }
    },
  },
};
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

tbody {
  td {
    padding: 0px !important;
  }
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 100vh !important;
}
</style>
