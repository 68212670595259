<template></template>

<script>
import * as THREE from "three";
import * as maptalks from "maptalks";
import { GeoDataSet } from "geojsondataset";

const material = new THREE.MeshPhongMaterial({ color: '#0A0A0A' });
const materialHighlight = new THREE.MeshPhongMaterial({ color: '#FFC000' });

export default {
  props: ["refMap", "refThreeLayer"],
  data: () => ({
    geoDataSet: null,
    dataGroupId: 'parks',
    selectMesh: null,
    parksFeatures: [],
  }),
  mounted() {
    this.$root.$on('highlightPark', this.highlightPark);
    this.initScene();
  },
  methods:
  {
    loadParks() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("parks/GET_PARKS_GEOJSON")
          .then((geojson) => {
            this.geoDataSet.addGeoData(this.dataGroupId, geojson);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    initScene() {

      this.geoDataSet = new GeoDataSet({ coordinateCount: 1000, loopFPS: 2 });

      this.geoDataSet.on(this.dataGroupId, (geojson) => {

        let parks = geojson.map(element => {
          const f = JSON.parse(element.feature);
          const code = f.properties.code;
          const polygon = maptalks.GeoJSON.toGeometry(f);

          polygon.setProperties({
            height: 0.01,
            bottomHeight: 0,
            code: code
          });
          return polygon;
        });

        let features = parks.slice(0, Infinity);
        const meshParks = this.threeLayer.toExtrudePolygons(features, { asynchronous: true, interactive: false }, material);
        this.threeLayer.delayAddMesh(meshParks);
        this.parksFeatures = this.parksFeatures.concat(features);
      })

      this.loadParks();
    },

    highlightPark(code) {

      const selectPolygon = this.parksFeatures.find((park) => park.properties.code == code);

      if (selectPolygon) {
        if (!!this.selectMesh) {
          this.threeLayer.removeMesh(this.selectMesh);
          this.selectMesh = null;
        }
        this.selectMesh = this.threeLayer.toExtrudePolygon(selectPolygon, Object.assign({}, selectPolygon.properties, { topColor: '#fff', interactive: false }), materialHighlight);
        this.threeLayer.addMesh(this.selectMesh);
      }

      setTimeout(() => {
        if (!!this.selectMesh) {
          this.threeLayer.removeMesh(this.selectMesh);
          this.selectMesh = null;
        }
      }, 2000);
    },
  },
  beforeDestroy() {
    this.$root.$off('highlightPark', this.highlightPark);
  },
  computed: {
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },

    threeLayer: {
      get() {
        return this.refThreeLayer;
      },
      set(val) {
        this.$emit("update:refThreeLayer", val);
      },
    },
  },
};
</script>