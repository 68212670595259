<template>
  <div>
    <v-dialog v-model="dialogOpen" persistent max-width="290">
      <v-card tile :loading="loading">
        <v-card-title class="headline">{{
      $t("global.eliminate")
    }}</v-card-title>
        <v-card-text>{{ $t("global.confirmation_eliminate") }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text :disabled="loading" @click="cancelDelete">{{ $t("global.no") }}</v-btn>
          <v-btn color="red darken-1" text :disabled="loading" @click="confirmDelete">{{ $t("global.yes") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  props: ["item", "open"],
  data() {
    return { loading: false };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  methods: {
    cancelDelete() {
      this.dialogOpen = false;
    },
    confirmDelete() {
      this.loading = true;
      this.$store
        .dispatch("operators/DELETE_OPERATOR", this.item.id)
        .then(() => {
          this.loading = false;
          this.dialogOpen = false;
        })
        .catch(() => {
          this.loading = false;
          this.dialogOpen = false;
        });
    },
  },
};
</script>
