import Tracker from "@/helpers/realtime/tracker";
import store from "@/store";

const LOOP_TIMEOUT = 2000; //ms
const NUMBER_OF_ACTIONS_TO_PERFORM = 5000; //X actions per LOOP_TIMEOUT

class ContainersTracker extends Tracker {
  constructor() {
    super();
  }

  async runLoop() {
    if (this._loopTimeout) {
      clearTimeout(this._loopTimeout);
    }
    if (!this.isRunning) {
      return;
    }

    //run here some action dispatching
    let actionsToPerform = this.actions.splice(0, NUMBER_OF_ACTIONS_TO_PERFORM);

    // If this has some job to do
    if (actionsToPerform.length > 0) {
      store.dispatch("containers/UPDATE_CONTAINERS", actionsToPerform);
    }

    for (let i = 0; i < actionsToPerform.length; i++) {
      await this.dispatchActionToPerform(actionsToPerform[i]);
    }
    this._loopTimeout = setTimeout(this.runLoop.bind(this), LOOP_TIMEOUT);
  }
}

//Singleton helper
const instance = new ContainersTracker();
//Object.freeze(instance);
export default instance;
