import Vue from 'vue';
import VueI18n from 'vue-i18n';
import vuetifyLocales from "./vuetify_locales";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/translations', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  let messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  messages = {
    en: {
      ...messages.en,
      $vuetify: vuetifyLocales.en,
    },
    pt: {
      ...messages.pt,
      $vuetify: vuetifyLocales.pt,
    },
  }
  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});

/**
 * Set locale to new value at Vue and Vuex.
 *
 * @param {Boolean} newLocale themeDark new value
 */
export async function setLocale(locale) {
  if (i18n.locale !== locale) {
    i18n.locale = locale || process.env.VUE_APP_I18N_LOCALE;
  } else {
    console.warn(`[Locale] "${locale}" is current`);
  }
}

export default i18n;