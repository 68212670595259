<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.parks") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.parks_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div class="float-right">
        <v-btn depressed v-if="hasUserPermissionToViewEditOrManage('PARKS')" class="mb-5" color="primary"
          @click="importGeoJSON">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("global.import_parks") }}
        </v-btn>
      </div>
    </div>
    <v-row class="fill-height">
      <v-col cols="6" sm="6" md="6" lg="7">
        <Map :selected.sync="selectedPark" :key="componentKey" />
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="5">
        <v-card tile elevation="0" outlined>
          <v-card-text class="ma-0 pa-0">
            <List @remove="remove" @edit="edit" :key="componentKey" :selected.sync="selectedPark" :page.sync="page"
              :search.sync="search" />
          </v-card-text></v-card>
      </v-col>
    </v-row>
    <ImportParkDialog :open.sync="openImportParkDialog" />
    <EditParkDialog :open.sync="openEditParkDialog" :item="selectedPark" />
    <DeleteParkDialog :open.sync="openDeleteParkDialog" :item="selectedPark" />
  </div>
</template>

<script>
import List from "./Parks/List";
import Map from "./Parks/Map";
import EditParkDialog from "./Parks/EditParkDialog";
import DeleteParkDialog from "./Parks/DeleteParkDialog";
import ImportParkDialog from "./Parks/ImportParkDialog";
export default {
  components: {
    Map,
    List,
    EditParkDialog,
    DeleteParkDialog,
    ImportParkDialog,
  },
  data() {
    return {
      componentKey: 0,
      openEditParkDialog: false,
      openDeleteParkDialog: false,
      openImportParkDialog: false,
      selectedPark: [],
      search: "",
      page: 1,
    };
  },
  watch: {
    openDeleteParkDialog(val) {
      if (val == false) this.forceRerender();
    },
    openImportParkDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  computed:
  {
    parks() {
      return this.$store.state.parks.list;
    },
  },
  methods: {
    edit(park) {
      this.selectedPark = [park];
      this.openEditParkDialog = true;
    },
    remove(park) {
      this.selectedPark = [park];
      this.openDeleteParkDialog = true;
    },
    importGeoJSON() {
      this.openImportParkDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
