<template>
    <Types />
</template>
  
<script>
import Types from "@/components/Configurations/Types";

export default {
    components: {
        Types,
    },
};
</script>
  