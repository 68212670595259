<template>
    <Cells />
</template>
  
<script>
import Cells from "@/components/Configurations/Cells";

export default {
    components: {
        Cells,
    },
};
</script>
  