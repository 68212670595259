import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const operatorsService = {
  getOperators,
  updateOperator,
  deleteOperator,
  createOperator,
  importOperators,
};

async function getOperators() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/operators", requestOptions);
}

async function updateOperator(operator) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/operators/" + operator.id,
    operator,
    requestOptions
  );
}

async function deleteOperator(id) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/operators/" + id,
    requestOptions
  );
}

async function createOperator(operator) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/operators",
    operator,
    requestOptions
  );
}

async function importOperators(file) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/operators/import",
    bodyFormData,
    requestOptions
  );
  return res;
}
