<template>
  <v-dialog v-model="isOpen" persistent width="900" scrollable>
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div v-for="(message, index) in this.messages" :key="index" class="subtitle">
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear height="10" striped color="lime" indeterminate v-show="loading"></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("global.create_user") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field counter="255" maxlength="255" v-model="item.name" required :label="$t('users.name')"
            :rules="nameRules" outlined />
          <v-text-field counter="255" maxlength="255" v-model="item.username" required :label="$t('users.username')"
            :rules="usernameRules" outlined />
          <v-text-field counter="255" maxlength="255" v-model="item.email" :label="$t('users.email')"
            :rules="emailRules" autocomplete="new-email" outlined />
          <v-text-field type="password" v-model="item.password" :label="$t('users.password')" :rules="passwordRules"
            autocomplete="new-password" outlined />
          <v-text-field type="password" v-model="item.confirmPassword" :label="$t('users.password_confirm')"
            :rules="confirmPasswordRules" outlined />
          <LocodeSelectionInput v-model="item.locodes" />
          <LocodeDefaultSelectionInput v-model="item.default_locode" :locodes.sync="item.locodes" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LocodeSelectionInput from "./LocodeSelectionInput";
import LocodeDefaultSelectionInput from "./LocodeDefaultSelectionInput";

const DEFAULT_ITEM = {
  name: "",
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  locodes: [],
  default_locode: "",
};
export default {
  props: ["open"],
  components: { LocodeSelectionInput, LocodeDefaultSelectionInput },
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("global.name_rules"),
        (v) =>
          (v && v.length <= 255) || this.$t("global.character_limit_rules"),
      ],
      usernameRules: [
        (v) => !!v || this.$t("global.name_rules"),
        (v) =>
          /^(?=[a-zA-Z0-9._\u00C0-\u00FF]{1,255}$)^\S*$/.test(v) ||
          this.$t("global.username_rules"),
      ],
      emailRules: [
        (v) => !!v || this.$t("global.email_rules"),
        (v) => /.+@.+/.test(v) || this.$t("global.email_valid"),
        (v) =>
          (v && v.length <= 255) || this.$t("global.character_limit_rules"),
      ],
      passwordRules: [
        (v) => !!v || this.$t("global.password_required"),
        (v) =>
          (v && v.length <= 255) || this.$t("global.character_limit_rules"),
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t("global.password_required"),
        (v) =>
          this.item.password === v || this.$t("global.password_must_be_equal"),
        (v) =>
          (v && v.length <= 255) || this.$t("global.character_limit_rules"),
      ],
      locodesRules: [
        (v) => (!!v && v.length > 0) || this.$t("global.port_locode_required"),
      ],
      item: Object.assign({}, DEFAULT_ITEM),
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    currentLocode() {
      return this.$store.state.user.account.locode;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.item = Object.assign({}, DEFAULT_ITEM);

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.currentLocode) {
          this.item.locodes = [this.currentLocode.code];
        }
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        let user = Object.assign({}, this.item);
        this.$store.dispatch("users/CREATE_USER", user).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
