<template></template>

<script>
import * as THREE from "three";
import * as maptalks from "maptalks";
import { GeoDataSet } from "geojsondataset";
import debounce from "debounce";
import { segregations } from "@/helpers/segregations";

const materialHighlight = new THREE.MeshPhongMaterial({ color: "#FFC000" });

export default {
  props: ["refMap", "bic", "refThreeLayer"],

  data: () => ({
    geoDataSet: null,
    dataGroupId: "containers",
    selectMesh: null,
    allMeshs: [],
    containersFeatures: [],

    // Segregations
    segregations: segregations,
    segregationMaterials: {},
  }),

  computed: {
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    selectedBic: {
      get() {
        return this.bic;
      },
      set(val) {
        this.$emit("update:bic", val);
      },
    },
    threeLayer: {
      get() {
        return this.refThreeLayer;
      },
      set(val) {
        this.$emit("update:refThreeLayer", val);
      },
    },
    parks() {
      return this.$store.state.parks.list;
    },

    containers() {
      return this.$store.state.containers.list;
    },
  },

  watch: {
    selectedBic(val) {
      if (val) {
        this.allMeshs.forEach((mesh) => {
          let material = mesh.getSymbol();
          material.opacity = 0.2;
          material.transparent = true;
          mesh.setSymbol(material);
        });
      } else {
        this.allMeshs.forEach((mesh) => {
          let material = mesh.getSymbol();
          material.opacity = 1;
          material.transparent = false;
          mesh.setSymbol(material);
        });
      }
      this.showContainerInfo(val);
    },

    containers() {
      if (this.containers)
        this.redrawContainers({
          type: "FeatureCollection",
          features: this.containers,
        });
    },
  },

  mounted() {
    this.$root.$on("highlightContainer", this.highlightContainer);
    this.initScene();
    this.createSegregationMaterials();
  },

  beforeDestroy() {
    this.$root.$off("highlightContainer", this.highlightContainer);
  },

  methods: {
    createSegregationMaterials() {
      this.segregationMaterials = this.segregations.reduce((materials, s) => {
        materials[s.code] = {
          containers: [],
          material: new THREE.MeshPhongMaterial({ color: s.color }),
        };
        return materials;
      }, {});
    },

    redrawContainers: debounce(function (geojson) {
      this.removeAllDrawnContainers();
      if (this.geoDataSet) {
        this.geoDataSet.addGeoData(this.dataGroupId, geojson);
      }
    }, 500),

    removeAllDrawnContainers() {
      if (!this.threeLayer || !this.allMeshs) return;

      this.allMeshs.forEach((mesh) => {
        this.threeLayer.removeMesh(mesh);
      });

      if (this.selectMesh) this.threeLayer.removeMesh(this.selectMesh);
    },

    initScene() {
      this.map.on("click", (e) => {
        let ovni = this.threeLayer.identify(e.coordinate);

        if (ovni.length == 0 && !!this.selectMesh) {
          this.threeLayer.removeMesh(this.selectMesh);
          this.selectMesh = null;
          this.selectedBic = null;
        }
      });

      this.geoDataSet = new GeoDataSet({ coordinateCount: 1000, loopFPS: 2 });

      const handleContainerMove = function (e) {
        if (e?.selectMesh?.data) {
          const bic = e.selectMesh.data.getProperties().bic;
          // 'this' refers to the scoped function and not the vue component
          const tooltip = this.getToolTip();
          tooltip._content = `${bic}`;
        }
      };

      const handleContainerClick = (e) => {
        const select = e.selectMesh;
        if (!!this.selectMesh) {
          this.threeLayer.removeMesh(this.selectMesh);
          this.selectMesh = null;
          this.selectedBic = null;
        }

        let data, baseObject;
        if (select) {
          data = select.data;

          baseObject = select.baseObject;
          if (baseObject && !baseObject.isAdd) {
            baseObject.setSymbol(materialHighlight);
            this.threeLayer.addMesh(baseObject);
            this.selectMesh = baseObject;
            this.selectedBic = data.properties.bic;
          }
        }
      };

      this.geoDataSet.on(this.dataGroupId, (geojson) => {
        let containers = geojson.features
          .filter((c) => {
            return !!c.geometry;
          })
          .map((f) => {
            const polygon = maptalks.GeoJSON.toGeometry(f);
            var hasHazmat = f.properties.hazmats != null;
            var height = (f.properties.height || 0) * 0.3048; //toMeters
            var bottomHeight = (f.properties.bottomHeight || 0) * 0.3048; //toMeters

            var bic = f.properties.bic || null;
            polygon.setProperties({
              height: height - 0.05,
              bottomHeight: bottomHeight,
              hasHazmat: hasHazmat,
              bic: bic,
              alerts: f.properties.alerts,
            });
            return polygon;
          });




        // loop containers
        // distribute each container with its own group

        for (let container of containers) {
          
          // Determinar o pior hazmat dos "alerts"
          let segregationCode = container.properties.hasHazmat ? -1 : 0;

          if (container.properties.alerts && container.properties.alerts.length > 0) {
            segregationCode = Math.max(...container.properties.alerts.map((c) => parseFloat(c.code)));
          }

          let segregation = this.segregationMaterials[segregationCode];
          segregation.containers.push(container);         

        }

        for (let segregationCode in this.segregationMaterials) {
          const segregation = this.segregationMaterials[segregationCode];

          if (segregation.containers.length == 0)
            continue;

          const mesh = this.threeLayer.toExtrudePolygons(
            segregation.containers,
            { asynchronous: true },
            segregation.material
          );
          
          mesh.setToolTip("", {
            showTimeout: 0,
            eventsPropagation: true,
            dx: 10,
          });
          
          mesh.on("mousemove", handleContainerMove);

          mesh.on("click", handleContainerClick);

          this.threeLayer.delayAddMesh(mesh);
          this.allMeshs = this.allMeshs.concat(mesh);
        }

      });
    },

    highlightContainer(bic) {
      const selectPolygon = this.containersFeatures.find(
        (park) => park.properties.bic == bic
      );

      if (selectPolygon) {
        if (!!this.selectMesh) {
          this.threeLayer.removeMesh(this.selectMesh);
          this.selectMesh = null;
          this.selectedBic = null;
        }
        this.selectMesh = this.threeLayer.toExtrudePolygon(
          selectPolygon,
          Object.assign({}, selectPolygon.properties, {
            topColor: "#fff",
            interactive: false,
          }),
          materialHighlight
        );
        this.threeLayer.addMesh(this.selectMesh);
        this.selectedBic = bic;
      }
    },

    showContainerInfo(bic) {
      this.$root.$emit("showContainer", bic);
    },
  },
};
</script>
