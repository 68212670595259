import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const parksService = {
  getParks,
  getGeoJson,
  importParks,
  updatePark,
  deletePark,
  statsPark,
  refresh,
};

async function getParks() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/parks", requestOptions);
}

async function getGeoJson() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/parks/geojson", requestOptions);
}

async function importParks(file) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/parks/import",
    bodyFormData,
    requestOptions
  );
  return res;
}

async function updatePark(park) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("name", park.name);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/parks/update/" + park.id,
    bodyFormData,
    requestOptions
  );
  return res;
}

async function deletePark(parkId) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.delete(
    configs.getApiUrl() + "/v1/parks/delete/" + parkId,
    requestOptions
  );
  return res;
}

async function statsPark(parkId) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.post(
    configs.getApiUrl() + "/v1/parks/stats/" + parkId,
    {},
    requestOptions
  );
  return res;
}


async function refresh(code) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.post(
    configs.getApiUrl() + `/v1/parks/${code}/refresh`,
    {},
    requestOptions
  );
  return res;
}
