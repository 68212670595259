import Vue from "vue";
import { Settings, DateTime } from "luxon";
import config from "@/config";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

// ========================== [ CUSTOM FILTERS] ==========================

Vue.filter("formatDatetimeFromDatabase", (value) => {
  return value
    ? DateTime.fromFormat(value, "yyyy-LL-dd HH:mm:ss", {
      zone: "utc",
    })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy HH:mm")
    : "";
});

Vue.filter("formatDatetime", (value) => {
  return value
    ? DateTime.fromISO(value, { zone: "utc" })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy HH:mm")
    : "";
});

Vue.filter("formatFullDatetime", (value) => {
  return value
    ? DateTime.fromISO(value, { zone: "utc" })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy HH:mm:ss")
    : "";
});

Vue.filter("formatDate", (value) => {
  return value
    ? DateTime.fromISO(value, { zone: "utc" })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy")
    : "";
});

Vue.filter("formatJSFullDatetime", (value) => {
  return value
    ? DateTime.fromJSDate(value)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy HH:mm:ss")
    : "";
});

Vue.filter("formatJSDatetime", (value) => {
  return value
    ? DateTime.fromJSDate(value)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy HH:mm")
    : "";
});

Vue.filter("formatJSDate", (value) => {
  return value
    ? DateTime.fromJSDate(value)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("dd/LL/yyyy")
    : "";
});

Vue.filter("formatNumber", (value) => {
  if (typeof Intl !== "undefined" && value) {
    value = Number(value);
    return Intl.NumberFormat(config.app_locale, {
      maximumFractionDigits: 2,
    }).format(value);
  }
  //return value as is
  return value;
});

// ========================== [/CUSTOM FILTERS] ==========================
