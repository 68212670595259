import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const cellsService = {
  getCells,
  getGeoJson,
  importCells,
  updateCell,
  deleteCell,
};

async function getCells() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/cells", requestOptions);
}

async function getGeoJson() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/cells/geojson", requestOptions);
}

async function importCells(file) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/cells/import",
    bodyFormData,
    requestOptions
  );
  return res;
}

async function updateCell(cell) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("name", cell.name);

  return await axios.post(
    configs.getApiUrl() + "/v1/cells/update/" + cell.id,
    bodyFormData,
    requestOptions
  );
}

async function deleteCell(cellId) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/cells/delete/" + cellId,
    requestOptions
  );
}
