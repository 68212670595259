<template>
  <div class="map-container">
    <div ref="map" class="map" />
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import * as maptalks from "maptalks";
const CELLS_LAYERNAME = "cells";
export default {
  props: ["selected"],
  data() {
    return {
      map: null,
      loading: false,
      cellsHighlightSymbol: {
        lineWidth: 5,
        lineColor: "yellow",
        polygonOpacity: 0.2,
      },
      cellsSymbol: {
        lineColor: "#34495e",
        lineWidth: 1,
        polygonFill: "rgb(231,209, 92)",
        polygonOpacity: 0.6,
        textFaceName: "Roboto",
        textName: "{code}", //value from name in geometry's properties
        textWeight: "bold", //'bold', 'bold'
        textStyle: "normal", //'italic', 'oblique'
        textSize: {
          stops: [
            [18, 0],
            [19, 8],
            [20, 12],
          ],
        },
        textOpacity: 1,
        textLineSpacing: 0,
        textDx: 0,
        textDy: 0,
        textHorizontalAlignment: "middle", //left | middle | right | auto
        textVerticalAlignment: "middle", // top | middle | bottom | auto
        textAlign: "center", //left | right | center | auto,
        textFill: "#34495e",
      },
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
    cells() {
      return this.$store.state.cells.geojson;
    },
    selectedCell: {
      get() {
        return this.selected[0];
      },
      set(val) {
        this.$emit("update:selected", [val]);
      },
    },
  },
  watch: {
    cells() {
      this.processCells();
    },
    selectedCell(val) {
      if (val) this.selectCell(val);
    }
  },
  mounted() {
    this.initMap();
    this.loadCells();
  },
  beforeDestroy() {
    this.map.off("click", this.handleMapClick);
  },
  methods: {
    initMap() {
      let center;
      let bearing;
      let zoom;
      try {
        center = this.locode.coordinates;
        bearing = this.locode.bearing;
        zoom = this.locode.zoom;
      } catch (e) {
        console.error(e);
        center = [-8.702487, 41.183199];
        bearing = -38;
        zoom = 15;
      }
      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        layerSwitcherControl: false,
        baseLayer: new maptalks.TileLayer("tile", {
          urlTemplate:
            "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png",
          subdomains: ["a", "b", "c", "d"],
          attribution: "",
        }),
        //]),
        layers: [new maptalks.VectorLayer(CELLS_LAYERNAME, [])],
      });
      this.map.on("click", this.handleMapClick);
    },
    loadCells() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("cells/GET_CELLS_GEOJSON")
          .then(() => {
            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
    selectCell(cell) {
      if (cell && cell.feature) {
        let polygon = new maptalks.Polygon(JSON.parse(cell.feature).geometry.coordinates);

        this.map.fitExtent(polygon.getExtent(), 0);

        this.map
          .getLayer(CELLS_LAYERNAME)
          .forEach((f) => f.properties.id == cell.id ? f.updateSymbol(this.cellsHighlightSymbol) : f.setSymbol(this.cellsSymbol));
      }

    },
    processCells() {
      if (this.map) {
        let layer = this.map.getLayer(CELLS_LAYERNAME);
        layer.clear();
        if (this.cells) {
          this.cells.forEach((cell) => {
            let geometry = maptalks.GeoJSON.toGeometry(cell.feature);
            if (geometry) {
              let data = JSON.parse(cell.feature);
              geometry.setSymbol(this.cellsSymbol);
              geometry.setProperties({
                id: data.properties.id,
                code: data.properties.code,
                name: data.type,
              });
              geometry.addTo(layer);
              geometry.on("click", this.handleCellClicked);
            }
          });
        }
      }
    },
    deSelectCells() {
      if (this.map) {
        this.map
          .getLayer(CELLS_LAYERNAME)
          .forEach((l) => l.setSymbol(this.cellsSymbol));
      }
      this.selectedCell = null;
    },
    handleCellClicked(e) {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      this.deSelectCells();
      if (e.target) {
        e.target.updateSymbol(this.cellsHighlightSymbol);
        let found = this.cells.find((z) => {
          return z.id == e.target.properties.id;
        });

        if (found) {
          this.selectedCell = found;
        }
      }
    },
    handleMapClick() {
      this.deSelectCells();
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
}
</style>

<style scoped src="@/../node_modules/maptalks/dist/maptalks.css"></style>
