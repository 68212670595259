<template>
  <v-autocomplete
    v-model="selectedLocodes"
    :rules="rules"
    :items="locodes"
    :label="$t('users.locodes')"
    item-text="name"
    item-value="code"
    :item-disabled="customDisabledOptions"
    :filter="nameAndCodeFilter"
    outlined
    multiple
  >
    <template v-slot:selection="data">
      <v-chip small dark class="mr-1">{{ data.item.code }}</v-chip>
    </template>
    <template v-slot:item="data">
      <v-chip small dark class="mr-1">{{ data.item.code }}</v-chip>
      {{ data.item.name }}
    </template>
  </v-autocomplete>
</template>
<script>
import configs from "@/helpers/configs";
export default {
  props: ["value", "rules"],
  computed: {
    currentLocode() {
      return this.$store.state.user.account.locode;
    },
    selectedLocodes: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    locodes() {
      return configs.getLocodes();
    },
  },
  methods: {
    customDisabledOptions(item) {
      return item && this.currentLocode && item.code == this.currentLocode.code;
    },
    nameAndCodeFilter(item, queryText) {
      return (
        (item.code &&
          item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
            -1) ||
        (item.name &&
          item.name
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase())) > -1
      );
    },
  },
};
</script>