<template>
  <v-container fluid fill-height class="pa-0" style="height: calc(100vh - 50px)">
    <splitpanes class="default-theme" horizontal style="height: 100%">
      <pane max-size="100" size="70">
        <v-progress-linear height="10" indeterminate color="yellow darken-2" v-if="loading"></v-progress-linear>
        <Map :refMap.sync="map" :refThreeLayer.sync="threeLayer" :refShipsLayer.sync="shipsLayer"
          :bic.sync="selectedBic" />          
        <Containers :refMap.sync="map" :refThreeLayer.sync="threeLayer" :bic.sync="selectedBic"
          v-if="!!map && !!threeLayer" />
        <Buildings :refMap.sync="map" :refThreeLayer.sync="threeLayer" v-if="!!map && !!threeLayer" />
        <Parks :refMap.sync="map" :refThreeLayer.sync="threeLayer" v-if="!!map && !!threeLayer" />
        <Cells :refMap.sync="map" :refThreeLayer.sync="threeLayer" v-if="!!map" />
        <AISShips :refMap.sync="map" :refShipsLayer.sync="shipsLayer" v-if="!!map" />
      </pane>
      <pane size="30">
        <List :bic.sync="selectedBic" />
      </pane>
    </splitpanes>
  </v-container>
</template>

<script>
import Map from "@/components/Main/Map";
import Containers from "@/components/Map/Containers";
import Parks from "@/components/Map/Parks";
import Buildings from "@/components/Map/Buildings";
import List from "@/components/Containers/List";
import Cells from "@/components/Map/Cells";
import AppBar from "@/components/Main/AppBar";
import { Splitpanes, Pane } from 'splitpanes'
import AISShips from "@/components/Widget/AISShips.vue";
import 'splitpanes/dist/splitpanes.css'

import SocketServiceHelper from "@/helpers/realtime/socketservicehelper";

export default {

  components: {
    Map,
    Containers,
    Parks,
    Buildings,
    Cells,
    AppBar,
    Splitpanes,
    Pane,
    List,
    AISShips,

  },

  data() {
    return {
      map: null,
      threeLayer: null,
      shipsLayer: null,
      selectedBic: null,
    };
  },

  computed:
  {
    loading() {
      return this.$store.state.containers.loading;
    },
  },

  mounted() {
    this.$root.$on('showContainer', this.showContainer);
    SocketServiceHelper.initialize();
  },
  beforeDestroy() {
    this.$root.$off('showContainer', this.showContainer);
    SocketServiceHelper.deInitialize();
  },

  methods:
  {
    showContainer(val) {
      this.selectedBic = val;
    }
  }
};
</script>

<style>
.main-content {
  height: calc(100vh - var(--toolbar-height));
  position: relative;
  overflow: hidden;
}
</style>
