import * as THREE from 'three';
import Ship from "@/digitaltwin/Ship";
class Utils {

    constructor() {
        this.materials = new Map();
        this.materialAlerts = new Map();
    }

    getHighlightMaterial() {
        return new THREE.MeshLambertMaterial({
            color: "yellow",
            transparent: false,
        });
    }

    feet2Meters(val) {
        return val / 3.2808;
    }

    getShipMesh(ship, layer) {
        let coordinate = [parseFloat(ship.lon), parseFloat(ship.lat)];
        let length = 1000;
        let width = 5000;
        let height = 1000;
        let altitude = 0;

        let props = {
            altitude: this.feet2Meters(altitude),
            length: this.feet2Meters(length),
            height: this.feet2Meters(height),
            width: this.feet2Meters(width),
            data: ship,
        };

        let shipMesh = new Ship(
            ship,
            coordinate,
            props,
            layer
        );

        return shipMesh;
    }

}

//Singleton helper
const instance = new Utils();

export default instance;