import Vue from "vue";
import { imdgsService } from "@/api_services/imdgs.service";

let initialState = { list: [] };

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    setImdgs(state, imdgs) {
      state.list = imdgs;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_IMDGS: (context) => {
      return new Promise((resolve, reject) => {
        imdgsService
          .getImdgs()
          .then((res) => {
            context.commit("setImdgs", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_IMDG: (context, imdg) => {
      return new Promise((resolve, reject) => {
        imdgsService
          .updateImdg(imdg)
          .then(({ data }) => {
            context.dispatch("GET_IMDGS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE_IMDG: (context, id) => {
      return new Promise((resolve, reject) => {
        imdgsService
          .deleteImdg(id)
          .then(() => {
            context.dispatch("GET_IMDGS");
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE_IMDG: (context, imdg) => {
      return new Promise((resolve, reject) => {
        imdgsService
          .createImdg(imdg)
          .then(({ data }) => {
            context.dispatch("GET_IMDGS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET_STATE_IMDGS: (context) => {
      context.commit("resetState");
    },
    IMPORT_IMDGS: (context, file) => {
      return new Promise((resolve, reject) => {
        imdgsService
          .importImdgs(file)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_IMDGS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
