<template>
  <v-data-table :loading="loading" :search="search" :page.sync="page" :headers="headers" :items="items"
    :items-per-page="5" item-key="id" fixed-header sort-by="code" must-sort min-height="calc(100vh - 290px)"
    :footer-props="{ 'show-first-last-page': true }">
    <template v-slot:top>
      <v-text-field v-model="search" single-line :label="$t('global.search_imdg')" prepend-inner-icon="mdi-magnify"
        outlined />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-menu v-if="hasUserPermissionToViewEditOrManage('IMDGS')" bottom absolute>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="hasUserPermissionToManage('IMDGS')" @click="remove(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="hasUserPermissionToViewEditOrManage('IMDGS')" @click="edit(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:[`item.color`]="{ item }">
      <v-icon :color="item.color" large>mdi-square-rounded</v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["selected"],
  data() {
    return {
      search: "",
      page: 1,
      loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("global.code"),
          value: "code",
        },
        {
          text: this.$t("global.color"),
          value: "color",
        },
        {
          text: this.$t("users.name"),
          value: "name",
        },

      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.imdgs.list.sort((a, b) =>
        a.code > b.code ? 1 : b.code > a.code ? -1 : 0
      );
    },
    selectedType: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("imdgs/GET_IMDGS").then(() => {
      this.loading = false;
    });
  },
  methods: {
    edit(item) {
      item.color = item.color ? item.color : 'FF0000FF';
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>
