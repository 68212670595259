<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.cells") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.cells_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div class="float-right">
        <v-btn depressed v-if="hasUserPermissionToViewEditOrManage('CELLS')" class="mb-5" color="primary"
          @click="importGeoJSON">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("global.import_cells") }}
        </v-btn>
      </div>
    </div>
    <v-row class="fill-height">
      <v-col cols="6" sm="6" md="6" lg="7">
        <Map :selected.sync="selectedCell" :key="componentKey" />
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="5">
        <v-card tile elevation="0" outlined>
          <v-card-text class="ma-0 pa-0">
            <List @remove="remove" @edit="edit" :key="componentKey" :selected.sync="selectedCell" :page.sync="page"
              :search.sync="search" />
          </v-card-text></v-card>
      </v-col>
    </v-row>
    <ImportCellDialog :open.sync="openImportCellDialog" />
    <EditCellDialog :open.sync="openEditCellDialog" :item="selectedCell" />
    <DeleteCellDialog :open.sync="openDeleteCellDialog" :item="selectedCell" />
  </div>
</template>

<script>
import List from "./Cells/List";
import Map from "./Cells/Map";
import EditCellDialog from "./Cells/EditCellDialog";
import DeleteCellDialog from "./Cells/DeleteCellDialog";
import ImportCellDialog from "./Cells/ImportCellDialog";
export default {
  components: {
    Map,
    List,
    EditCellDialog,
    DeleteCellDialog,
    ImportCellDialog,
  },
  data() {
    return {
      componentKey: 0,
      openEditCellDialog: false,
      openDeleteCellDialog: false,
      openImportCellDialog: false,
      selectedCell: [],
      search: "",
      page: 1,
    };
  },
  watch: {
    openDeleteCellDialog(val) {
      if (val == false) this.forceRerender();
    },
    openImportCellDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  computed:
  {
    cells() {
      return this.$store.state.cells.list;
    },
  },
  methods: {
    edit(cell) {
      this.selectedCell = [cell];
      this.openEditCellDialog = true;
    },
    remove(cell) {
      this.selectedCell = [cell];
      this.openDeleteCellDialog = true;
    },
    importGeoJSON() {
      this.openImportCellDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
