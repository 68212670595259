<template>
  <v-dialog v-model="isOpen" persistent width="900" scrollable>
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div v-for="(message, index) in this.messages" :key="index" class="subtitle">
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear height="10" striped color="lime" indeterminate v-show="loading"></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("global.edit_user") }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
          <v-alert v-if="item.is_ldap" text icon="mdi-information-outline">
            {{ $t("users.ldap_user_explanation") }}
          </v-alert>
          <v-text-field counter="255" maxlength="255" v-model="itemToEdit.name" required :label="$t('users.name')"
            :rules="nameRules" outlined />
          <v-text-field :disabled="itemToEdit.is_ldap" counter="255" maxlength="255" v-model="itemToEdit.username"
            required :label="$t('users.username')" :rules="usernameRules" outlined />
          <v-text-field :disabled="itemToEdit.is_ldap" counter="255" maxlength="255" v-model="itemToEdit.email"
            :label="$t('users.email')" :rules="emailRules" outlined autocomplete="new-email" />
          <v-text-field :disabled="itemToEdit.is_ldap" type="password" v-model="itemToEdit.password"
            :label="$t('configurations.update_password_if_necessary')" :rules="passwordRules" outlined
            autocomplete="new-password" />
          <v-text-field :disabled="itemToEdit.is_ldap" type="password" v-model="itemToEdit.confirmPassword"
            :label="$t('users.password_confirm')" :rules="confirmPasswordRules" outlined
            autocomplete="new-confirmPassword" />
          <LocodeSelectionInput v-model="item.locodes" :rules="locodesRules" />
          <LocodeDefaultSelectionInput v-model="item.default_locode" :locodes.sync="item.locodes" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LocodeSelectionInput from "./LocodeSelectionInput";
import LocodeDefaultSelectionInput from "./LocodeDefaultSelectionInput";

export default {
  props: ["open", "item"],
  components: { LocodeSelectionInput, LocodeDefaultSelectionInput },
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("global.name_rules"),
        (v) =>
          (v && v.length <= 255) || this.$t("global.character_limit_rules"),
      ],
      usernameRules: [
        (v) => !!v || this.$t("global.name_rules"),
        (v) =>
          /^(?=[a-zA-Z0-9\u00C0-\u00FF._]{1,255}$)^\S*$/.test(v) ||
          this.$t("global.username_rules"),
      ],

      passwordRules: [],
      confirmPasswordRules: [
        (v) =>
          this.itemToEdit.password === v ||
          this.$t("global.password_must_be_equal"),
      ],
      locodesRules: [
        (v) => (!!v && v.length > 0) || this.$t("global.port_locode_required"),
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit: {
      get() {
        return JSON.parse(JSON.stringify(this.item));
      },

      set(val) {
        this.$emit("update:item", val);
      },
    },
    emailRules() {
      if (this.item && this.item.is_ldap) {
        return [];
      } else {
        return [
          (v) => !!v || this.$t("global.password_must_be_equal"),
          (v) => /.+@.+/.test(v) || this.$t("global.email_valid"),
          (v) =>
            (v && v.length <= 255) || this.$t("global.character_limit_rules"),
        ];
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store.dispatch("users/UPDATE_USER", this.itemToEdit).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
