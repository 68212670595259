<template>
    <Parks />
</template>
  
<script>
import Parks from "@/components/Configurations/Parks";

export default {
    components: {
        Parks,
    },
};
</script>
  