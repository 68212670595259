import Vue from "vue";
import { segregationsService } from "@/api_services/segregations.service";

let initialState = { list: [] };

export default {
  state: initialState,
  getters: {},
  mutations: {
    setSegregations(state, segregations) {
      state.list = segregations;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_SEGREGATIONS: (context) => {
      return new Promise((resolve, reject) => {
        segregationsService
          .getSegregations()
          .then((res) => {
            context.commit("setSegregations", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_SEGREGATIONS: (context, segregation) => {
      return new Promise((resolve, reject) => {
        segregationsService
          .updateSegregation(segregation)
          .then((data) => {
            context.dispatch("GET_SEGREGATIONS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE_SEGREGATIONS: (context, id) => {
      return new Promise((resolve, reject) => {
        segregationsService
          .deleteSegregation(id)
          .then(() => {
            context.dispatch("GET_SEGREGATIONS");
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE_SEGREGATIONS: (context, segregation) => {
      return new Promise((resolve, reject) => {
        segregationsService
          .createSegregation(segregation)
          .then((data) => {
            context.dispatch("GET_SEGREGATIONS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET: (context) => {
      context.commit("resetState");
    },
    IMPORT_SEGREGATIONS: (context, file) => {
      return new Promise((resolve, reject) => {
        segregationsService
          .importSegregations(file)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_SEGREGATIONS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
