<template>
    <v-dialog v-model="isOpen" persistent width="900">
        <v-card tile>
            <v-progress-linear height="10" striped color="lime" indeterminate v-show="loading"></v-progress-linear>
            <v-toolbar dark color="primary" flat>
                <v-toolbar-title>
                    <span>
                        <v-icon left>mdi-upload</v-icon>{{ $t("global.import") }}</span>
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon dark @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pa-5">
                <v-alert color="blue-grey" text icon="mdi-information-outline">
                    {{ $t("global.alert_segregation") }}
                </v-alert>
                <v-form ref="form" class="mt-10">
                    <v-file-input v-model="file" show-size accept=".json" outlined prepend-icon
                        prepend-inner-icon="mdi-paperclip" :error-messages="fileUploadErrorMessage" :rules="fileRules"
                        persistent-hint :label="$t('global.json_file')"></v-file-input>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="importFile" :disabled="loading">
                    {{ $t("global.import") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["open"],
    data() {
        return {
            file: null,
            fileUploadErrorMessage: "",
            fileRules: [(v) => !!v || "O ficheiro é obrigatório"],
            loading: false,
        };
    },
    watch: {
        isOpen(val) {
            if (val) {
                this.file = null;

                if (this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
            }
        },
    },
    computed: {
        isOpen: {
            get() {
                return this.open;
            },
            set(val) {
                this.$emit("update:open", val);
            },
        },
    },
    methods: {
        close() {
            this.isOpen = false;
        },
        importFile() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$store
                    .dispatch("IMPORT_SEGREGATIONS", this.file)
                    .then(() => {
                        this.loading = false;
                        this.close();
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>