<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.operators") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.operators_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div class="float-right" v-if="hasUserPermissionToViewEditOrManage('OPERATORS')">
        <v-btn depressed class="mb-5" color="primary" @click="add">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("global.create_operator") }}
        </v-btn>
        <v-btn depressed class="mb-5 ml-1" color="primary" @click="importGeoJSON">
          <v-icon left>mdi-database-import</v-icon>
          {{ $t("global.import_operators") }}
        </v-btn>
      </div>
    </div>
    <List @remove="remove" @edit="edit" :selected.sync="selectedOperator" />
    <ImportOperatorDialog :open.sync="openImportOperatorDialog" />
    <CreateOperatorDialog :open.sync="openAddOperatorDialog" />
    <EditOperatorDialog :open.sync="openEditOperatorDialog" :item="selectedOperator" />
    <DeleteOperatorDialog :open.sync="openDeleteOperatorDialog" :item="selectedOperator" />
  </div>
</template>

<script>
import List from "./Operators/List";
import CreateOperatorDialog from "./Operators/CreateOperatorDialog";
import EditOperatorDialog from "./Operators/EditOperatorDialog";
import DeleteOperatorDialog from "./Operators/DeleteOperatorDialog";
import ImportOperatorDialog from "./Operators/ImportOperatorDialog";
export default {
  components: {
    List,
    CreateOperatorDialog,
    EditOperatorDialog,
    DeleteOperatorDialog,
    ImportOperatorDialog,
  },
  data() {
    return {
      openAddOperatorDialog: false,
      openEditOperatorDialog: false,
      openDeleteOperatorDialog: false,
      openImportOperatorDialog: false,
      selectedOperator: [],
    };
  },
  methods: {
    add() {
      this.openAddOperatorDialog = true;
    },
    edit(park) {
      this.selectedOperator = park;
      this.openEditOperatorDialog = true;
    },
    remove(park) {
      this.selectedOperator = park;
      this.openDeleteOperatorDialog = true;
    },
    importGeoJSON() {
      this.openImportOperatorDialog = true;
    },
  },
};
</script>
