<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div v-for="(message, index) in this.messages" :key="index" class="subtitle">
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear height="10" striped color="yellow darken-2" indeterminate v-show="loading"></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("global.create_type") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field counter="4" maxlength="4" v-model="item.code" required :label="$t('global.code')"
            :rules="codeRules" outlined />
          <v-text-field counter="255" maxlength="255" v-model="item.name" required :label="$t('users.name')"
            :rules="nameRules" outlined />
          <v-text-field maxlength="255" v-model="item.height" required :label="$t('global.height')" :rules="numberRules"
            type="number" outlined @keydown.69.prevent min="0" max="20" />
          <v-text-field maxlength="255" v-model="item.length" required :label="$t('global.length')" :rules="numberRules"
            type="number" outlined @keydown.69.prevent min="0" max="20" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="save" :disabled="loading">
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
const DEFAULT_ITEM = {
  code: "",
  name: "",
  height: "",
  length: "",
};
export default {
  props: ["open"],
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      codeRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => v.length < 5 || this.$t("rules.code_limit"),
      ],
      numberRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v > 0 && v < 20) || this.$t("rules.number_rules"),
      ],
      item: Object.assign({}, DEFAULT_ITEM),
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    locode() {
      return configs.getLocode();
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.item = Object.assign({}, DEFAULT_ITEM);

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      var that = this;
      that.expand = false;
      that.loading = true;
      that.messages = [];
      if (that.$refs.form.validate()) {
        this.item.port_locode = this.locode.code;
        let type = Object.assign({}, this.item);
        this.$store.dispatch("types/CREATE_TYPE", type).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
