<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div v-for="(message, index) in this.messages" :key="index" class="subtitle">
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear height="10" striped color="yellow darken-2" indeterminate v-show="loading"></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("global.create_operator") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-container>
            <v-text-field counter="4" maxlength="4" v-model="item.code" required :label="$t('operators.code')"
              :rules="codeRules" outlined />

            <v-text-field counter="255" maxlength="255" v-model="item.name" required :label="$t('operators.name')"
              :rules="nameRules" outlined />


            <v-text-field counter="255" maxlength="255" v-model="item.country" required :label="$t('operators.country')"
              :rules="countryRules" outlined />

            <v-text-field counter="255" maxlength="255" v-model="item.city" required :label="$t('operators.city')"
              :rules="cityRules" outlined />

            <v-select :items="filteredColors" item-text="name" :label="$t('operators.texture')" v-model="item.color"
              :rules="colorRules" outlined>
              <template v-slot:prepend-inner>
                <v-icon :color="item.color.toLowerCase()">mdi-square-rounded</v-icon>
              </template>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon :color="data.item.value.toLowerCase()" left>mdi-square-rounded</v-icon>{{ data.item.name
                    }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="save" :disabled="loading">
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
const DEFAULT_ITEM = {
  code: null,
  name: null,
  country: null,
  city: null,
  unavailable: false,
  color: "BLUE"
};
export default {
  props: ["open"],
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      colorRules: [(v) => !!v || this.$t("rules.required_field")],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      codeRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 4) || this.$t("rules.code_limit"),
      ],
      countryRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      cityRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      item: Object.assign({}, DEFAULT_ITEM),
      colors: [
        {
          name: this.$t('global.blue'), value: 'BLUE'
        },
        {
          name: this.$t('global.brown'), value: 'BROWN'
        },
        {
          name: this.$t('global.grey'), value: 'GREY'
        },
        {
          name: this.$t('global.green'), value: 'GREEN'
        },
        {
          name: this.$t('global.orange'), value: 'ORANGE'
        },
        {
          name: this.$t('global.yellow'), value: 'YELLOW'
        },
      ]
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    locode() {
      return configs.getLocode();
    },
    filteredColors() {
      return this.colors;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.item = Object.assign({}, DEFAULT_ITEM);
        if (this.$refs.form) this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      var that = this;
      that.expand = false;
      that.loading = true;
      that.messages = [];
      if (that.$refs.form.validate()) {
        this.item.port_locode = this.locode.code;
        let operator = Object.assign({}, this.item);
        this.$store.dispatch("operators/CREATE_OPERATOR", operator).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },

  },
}
</script>
