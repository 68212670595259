import configs from "@/helpers/configs";
import axios from "axios";
import {
  sharedHelpers
} from "@/api_services/utils/shared-helpers";

export const usersService = {
  getUsers,
  createUser,
  updateUser,
  updateUserPermissions,
  deleteUser,
  getLdapUsers,
  importLdapUsers,
};

async function getUsers() {
  let locode = await sharedHelpers.getLocode();
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      locode: locode.code,
    },
  };
  return await axios.get(configs.getApiUrl() + "/v1/users", requestOptions);
}

async function createUser(user) {
  let locode = await sharedHelpers.getLocode();
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      locode: locode.code,
    },
  };
  return await axios.post(configs.getApiUrl() + "/v1/users", user, requestOptions);
}

async function updateUser(user) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/users/" + user.id,
    user,
    requestOptions
  );
}

async function updateUserPermissions(user, permissions) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/users/" + user.id + "/permissions", {
    permissions
  },
    requestOptions
  );
}

async function deleteUser(userId) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/users/" + userId,
    requestOptions
  );
}

async function getLdapUsers() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/users/ldap",
    requestOptions
  );
}

async function importLdapUsers(users) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/users/ldap/import",
    users,
    requestOptions
  );
}