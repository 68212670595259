<template>
  <div class="overline">
    <status-indicator :status="!!updateDate ? 'active' : 'negative'" pulse class="mr-3" /><span v-if="!!updateDate"
      style="color: black !important">{{ updateDate | formatJSFullDatetime }} | {{ labelAIS }}</span>
  </div>
</template>

<script>
//Access vue instance to reach global constants
import * as maptalks from "maptalks";

const AISSHIPS_LAYERNAME = "ais_ships";

import AISShipsTracker from "@/helpers/realtime/aisshipstracker";

import { StatusIndicator } from "vue-status-indicator";

import Utils from "@/digitaltwin/Utils";

const HIGHLIGHTMATERIAL = Utils.getHighlightMaterial();

export default {
  props: {
    refMap: Object,
    refShipsLayer: Object,
  },
  components: {
    StatusIndicator,
  },
  data() {
    return {
      drawnShips: new Map(),
      updateDate: null,
    };
  },
  computed: {
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    shipsLayer: {
      get() {
        return this.refShipsLayer;
      },
      set(val) {
        this.$emit("update:refShipsLayer", val);
      },
    },
    labelAIS() {
      return "AIS (" + this.$store.state.ais_ships.all.length + " Navios)";
    },
  },
  mounted() {
    this.enableTracking();
  },
  beforeDestroy() {
    this.disableTracking();
  },
  methods: {
    clearLayer() {
      if (this.map) {
        if (this.map.getLayer(AISSHIPS_LAYERNAME))
          this.map.getLayer(AISSHIPS_LAYERNAME).clear();
      }
    },
    recreateLayer() {
      if (this.map) {
        if (!this.map.getLayer(AISSHIPS_LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(AISSHIPS_LAYERNAME));
        else this.map.getLayer(AISSHIPS_LAYERNAME).clear();
      }
    },
    enableTracking() {
      this.recreateLayer();
      AISShipsTracker.on("add", this.handleShipAdded);
      AISShipsTracker.on("update", this.handleShipUpdated);
      AISShipsTracker.on("remove", this.handleShipRemoved);
    },
    disableTracking() {
      this.clearLayer();
      AISShipsTracker.off("add", this.handleShipAdded);
      AISShipsTracker.off("update", this.handleShipUpdated);
      AISShipsTracker.off("remove", this.handleShipRemoved);
    },
    handleShipAdded(ship) {
      this.updateDate = new Date();
      if (ship && ship.lat && ship.lon) {
        if (!this.drawnShips.has(ship.id)) {
          let shipMesh = Utils.getShipMesh(ship, this.shipsLayer);
          if (this.shipsLayer.delayAddMesh) this.shipsLayer.delayAddMesh(shipMesh);
          else this.shipsLayer.addMesh(shipMesh);

          this.drawnShips.set(ship.id, shipMesh);

          shipMesh.setInfoWindow({
            content: `<div class="tooltip"> 
              <table style="width:100%">
                <tr>
                  <td>${ship.shipname || "ND"}</td>
                </tr>
                <tr>
                  <td>${ship.mmsi}</td>
                </tr>
                <tr>
                  <td>${shipMesh.getShipType(ship.cargo).name}</td>
                </tr>
              </table>
             </div>`,
            autopan: false,
          });

          this.registerEventsListener(ship, shipMesh);
        } else {
          this.handleShipUpdated(ship);
        }
      } else {
        console.warn("Not enough info to draw...", ship);
      }
    },
    handleShipUpdated(ship) {
      this.updateDate = new Date();
      if (ship) {
        this.handleShipRemoved(ship);
        this.handleShipAdded(ship);
      }
    },
    handleShipRemoved(ship) {
      this.updateDate = new Date();
      if (ship && this.drawnShips.has(ship.id)) {
        let shipMesh = this.drawnShips.get(ship.id);
        shipMesh.getObject3d().geometry.dispose();
        this.shipsLayer.removeMesh(shipMesh);
        this.drawnShips.delete(ship.id);
      }
    },
    registerEventsListener(ship, shipMesh) {
      shipMesh.off("mouseout");
      shipMesh.on("mouseout", function () {
        this.setSymbol(ship);
        this.closeInfoWindow();
      });

      shipMesh.off("mouseover");
      shipMesh.on("mouseover", function () {
        this.setSymbol(ship, HIGHLIGHTMATERIAL);
        this.openInfoWindow();
      });
    },
  },
};
</script>
