<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.imdgs") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.imdgs_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div class="float-right">
        <v-btn depressed v-if="hasUserPermissionToViewEditOrManage('IMDGS')" class="mb-5 mr-3" color="primary"
          @click="add">
          <v-icon left>mdi-plus</v-icon>
          {{ $t("global.create_imdg") }}
        </v-btn>
        <v-btn depressed v-if="hasUserPermissionToViewEditOrManage('IMDGS')" class="mb-5" color="primary"
          @click="importGeoJSON">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("global.import_imdgs") }}
        </v-btn>
      </div>
    </div>

    <v-row class="fill-height">
      <v-col style="min-height: 50vh" cols="12" sm="12" md="12" lg="12">
        <List @remove="remove" @edit="edit" :selected.sync="selectedIMDG" />
      </v-col>
    </v-row>
    <ImportIMDGDialog :open.sync="openImportIMDGDialog" />
    <CreateIMDGDialog :open.sync="openAddIMDGDialog" />
    <EditIMDGDialog :open.sync="openEditIMDGDialog" :item="selectedIMDG" />
    <DeleteIMDGDialog :open.sync="openDeleteIMDGDialog" :item="selectedIMDG" />
  </div>
</template>

<script>
import List from "./IMDGs/List";
import CreateIMDGDialog from "./IMDGs/CreateIMDGDialog";
import EditIMDGDialog from "./IMDGs/EditIMDGDialog";
import DeleteIMDGDialog from "./IMDGs/DeleteIMDGDialog";
import ImportIMDGDialog from "./IMDGs/ImportIMDGDialog";
export default {
  components: {
    List,
    CreateIMDGDialog,
    EditIMDGDialog,
    DeleteIMDGDialog,
    ImportIMDGDialog,
  },
  data() {
    return {
      openAddIMDGDialog: false,
      openEditIMDGDialog: false,
      openDeleteIMDGDialog: false,
      openImportIMDGDialog: false,
      selectedIMDG: [],
    };
  },
  methods: {
    add() {
      this.openAddIMDGDialog = true;
    },
    edit(type) {
      this.selectedIMDG = type;
      this.openEditIMDGDialog = true;
    },
    remove(type) {
      this.selectedIMDG = type;
      this.openDeleteIMDGDialog = true;
    },
    importGeoJSON() {
      this.openImportIMDGDialog = true;
    },
  },
};
</script>
