<template>
  <div class="px-2 pb-4" style="height:100%;">
    <v-data-table v-model="selectedContainers" :items-per-page="10" dense :headers="headers" :items="containersList"
      class="containers-table" must-sort :loading="loading" :loading-text="$t('global.loading_containers')"
      :search="searchTxt" item-key="bic" fixed-header height="calc(100% - 86px)" :group-by="groupBy" ref="card"
      :footer-props="{
      'items-per-page-options': [5, 10, 25, 50],
      'show-first-last-page': true,
    }">

      <template v-for="h in headers" v-slot:[`header.${h.value}`]="">
        <v-tooltip bottom color="black" v-bind:key="h.value">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span>{{ h.title || h.text }}</span>
        </v-tooltip>
      </template>

      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn depressed @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ group }}</span>
        </td>
      </template>

      <template v-slot:top>
        <v-row no-gutters>
          <v-col cols="8">
            <v-list class="py-0">
              <v-list-item-title class="text-h5 font-weight-black">
                {{ $t('global.containers_list') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('global.total') }}: {{ containersList.length }}
              </v-list-item-subtitle>
            </v-list>
          </v-col>
          <v-col cols="4">
            <v-row no-gutters class="pt-1">
              <v-col cols="6">
                <v-text-field v-model="searchTxt" :label="$t('global.search')" prepend-inner-icon="mdi-magnify" outlined
                  dense hide-details />
              </v-col>
              <v-col cols="6">
                <v-select v-model="groupBy" class="mr-1" :items="groups" outlined label="Agrupar por" dense clearable
                  hide-details :menu-props="{ bottom: true, offsetY: true }">
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>

                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-1"></v-divider>
      </template>

      <template v-slot:[`item.bic`]="{ item }">
        <div class="ma-0 pa-0">
          <v-btn :ref="item.bic" :disabled="!item.center_x || !item.center_y" @click.stop="focusOnContainer(item)"
            color="primary" outlined small width="100px">
            <b> {{ item.bic }}</b>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.hazmats="{ item }">
        <div class="ma-0 pa-0">
          <v-icon v-if="item.hazmats" color="red" small left>
            mdi-radioactive
          </v-icon>
          <span v-if="item.hazmats">Sim</span>
          <v-icon v-else color="green" small left>
            mdi-check
          </v-icon>
          <span v-if="!item.hazmats">Não</span>
        </div>
      </template>

      <template v-slot:item.time="{ item }">
        <div class="ma-0 pa-0">
          {{ item.time }}
        </div>
      </template>

      <template #footer.prepend>
        <v-spacer />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["bic"],
  data: () => ({
    searchTxt: null,
    groupBy: null,
    selectedContainers: [],
    groups: [
      { name: 'Parque', value: "park" },
      { name: 'Proprietário', value: "operator" },
      { name: 'Tipo', value: "type" },
    ],
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("global.bic"), value: "bic", width: "120px" },
        { text: this.$t("global.has_hazmats"), value: "hazmats" },
        { text: this.$t("global.date"), value: "time" },
        { text: this.$t("global.park"), value: "park" },
        { text: this.$t("global.starting_cell"), value: "cell_start" },
        { text: this.$t("global.ending_cell"), value: "cell_end" },
        { text: this.$t("global.level"), value: "level" },
        { text: this.$t("global.owner"), value: "operator" },
        { text: this.$t("global.type"), value: "type" },
        { text: this.$t("global.height"), value: "height" },
        { text: this.$t("global.length"), value: "length" },
      ];
    },
    containersList() {
      return this.$store.state.containers.list.map((c) => {
        return { ...c.properties, time: this.$options.filters.formatFullDatetime(c.properties.time) };
      });
    },
    loading() {
      return this.$store.state.containers.loading;
    },
    selectedBic: {
      get() {
        return this.bic;
      },
      set(val) {
        this.$emit("update:bic", val);
      },
    },
    locode() {
      return this.$store.state.user.account.locode;
    },
  },
  watch:
  {
    selectedBic(val) {
      if (val == null) this.selectedContainers = [];
      else this.selectedContainers = this.containersList.filter((c) => c.bic == val);
    },
  },
  methods: {
    focusOnContainer(item) {
      if (!!item.center_x && !!item.center_y) {
        this.selectedContainers = [item];
        this.$root.$emit('goTo', { coordinates: [item.center_x, item.center_y], zoomLevel: 21 });
        this.$root.$emit('highlightContainer', item.bic);
      }
    }
  },

};
</script>

<style>
.containers-table thead th {
  padding: 0px !important;
  text-align: center;
}

.containers-table tbody td {
  font-size: 12px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.containers-table .highlight {
  font-size: 12px !important;
  padding-top: 5px !important;
  font-weight: bolder !important;
}

.containers-table .v-input__control {
  width: 100px;
  margin-top: 2px;
  margin-right: 2px;
}

.containers-table {
  height: 100%;
}

tr.v-data-table__selected,
tr.v-data-table__selected:hover {
  background: yellow !important;
}
</style>
