import Vue from "vue";
import { cellsService } from "@/api_services/cells.service";

let initialState = {
  list: [],
  geojson: [],
  cellsList: [],
};

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    setCells(state, cells) {
      state.list = cells;
    },
    setGeoJsonCells(state, geojson) {
      state.geojson = geojson;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
    setCellsList(state, cell) {
      state.cellsList.push({ code: cell.code, coords: cell.coords });
    },
  },
  actions: {
    ADD_CELL_CELLS_LIST: (context, cell) => {
      context.commit("setCellsList", cell);
    },
    GET_CELLS: (context) => {
      return new Promise((resolve, reject) => {
        cellsService
          .getCells()
          .then((res) => {
            context.commit("setCells", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_CELLS_GEOJSON: (context) => {
      return new Promise((resolve, reject) => {
        cellsService
          .getGeoJson()
          .then((res) => {
            context.commit("setGeoJsonCells", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET_STATE_CELLS: (context) => {
      context.commit("resetState");
    },
    IMPORT_CELLS: (context, file) => {
      return new Promise((resolve, reject) => {
        cellsService
          .importCells(file)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_CELLS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_CELL: (context, cell) => {
      return new Promise((resolve, reject) => {
        cellsService
          .updateCell(cell)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_CELLS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE_CELL: (context, cellId) => {
      return new Promise((resolve, reject) => {
        cellsService
          .deleteCell(cellId)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_CELLS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
