<template>
    <IMDGs />
</template>
  
<script>
import IMDGs from "@/components/Configurations/IMDGs";

export default {
    components: {
        IMDGs,
    },
};
</script>
  