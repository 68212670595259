<template>
    <Segregations />
</template>

<script>
import Segregations from "@/components/Configurations/Segregations";

export default {
    components: {
        Segregations,
    },
};
</script>