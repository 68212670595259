import {
  userService
} from "@/api_services/user.service";
import {
  sharedHelpers
} from "@/api_services/utils/shared-helpers";
import configs from "@/helpers/configs";

export default {
  state: {
    account: {},
  },
  mutations: {
    setAccount(state, account) {
      state.account = JSON.parse(account);
    },
    setLocode(state, locode) {
      state.account = {
        ...state.account,
        locode: locode
      };
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    LOGIN: (context, credentials) => {
      return new Promise((resolve, reject) => {
        const {
          user,
          pass
        } = credentials;
        userService
          .login(user, pass)
          .then((res) => {
            if (res.status == 200) {
              let locodeStr = res.data.locode ?? "";
              let locodes = configs.getLocodes();
              let locode = locodes.find((l) => {
                return l.code == locodeStr;
              });
              let account = JSON.stringify(res.data);
              sharedHelpers.setStorageItemWithExpiry(
                "account",
                account,
                8640000 //24hours
              );
              sharedHelpers.setStorageItemWithExpiry(
                "locode",
                locode,
                8640000 //24hours
              );
              context.commit("setAccount", account);
              context.commit("setLocode", locode);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    SET_ACCOUNT: (context, account) => {
      context.commit("setAccount", account);
    },

    SET_LOCODE: (context, locode) => {
      context.commit("setLocode", locode);
    },

    LOGOUT: () => {
      userService.logout();
    },

    FORGOT_PASSWORD: (context, email) => {
      return new Promise((resolve, reject) => {
        userService
          .forgotPassword(email)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    RESET_PASSWORD: (context, credentials) => {
      const {
        email,
        password,
        token
      } = credentials;

      return new Promise((resolve, reject) => {
        userService
          .resetPassword(email, password, token)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};