import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import users from "./modules/users";
import parks from "./modules/parks";
import cells from "./modules/cells";
import containers from "./modules/containers";
import settings from "./modules/settings";
import types from "./modules/types";
import operators from "./modules/operators";
import imdgs from "./modules/imdgs";
import ais_ships from "./modules/ais_ships";
import segregations from "./modules/segregations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    users,
    containers,
    parks,
    cells,
    settings,
    types,
    operators,
    imdgs,
    ais_ships,
    segregations
  }, actions: {
    CLEAR_ALL(context) {
      // Clear existing namespaced stores
      context.dispatch("users/RESET", {}, { root: true });
      context.dispatch("containers/RESET", {}, { root: true });
      context.dispatch("parks/RESET", {}, { root: true });
      context.dispatch("cells/RESET", {}, { root: true });
      context.dispatch("settings/RESET", {}, { root: true });
      context.dispatch("types/RESET", {}, { root: true });
      context.dispatch("operators/RESET", {}, { root: true });
      context.dispatch("imdgs/RESET", {}, { root: true });
      context.dispatch("ais_ships/RESET", {}, { root: true });
      context.dispatch("segregations/RESET", {}, { root: true });
    },
  },
});
