<template>
  <v-autocomplete v-model="selectedLocode" :rules="rules" :items="list" :label="$t('users.default_locode')"
    item-text="name" item-value="code" :filter="nameAndCodeFilter" outlined>
    <template v-slot:selection="data">
      <v-chip small dark class="mr-1">{{ data.item.code }}</v-chip>
    </template>
    <template v-slot:item="data">
      <v-chip small dark class="mr-1">{{ data.item.code }}</v-chip>
      {{ data.item.name }}
    </template>
  </v-autocomplete>
</template>
<script>
import configs from "@/helpers/configs";
export default {
  props: ["value", "rules", "locodes"],
  computed: {
    currentLocode() {
      return this.$store.state.user.account.locode;
    },
    selectedLocode: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    list() {
      return configs.getLocodes().filter((locode) => { return this.locodes.includes(locode.code); });
    },
  },
  watch: {
    locodes() {
      //if locodes filter list change, and selectedLocode not exist in list, selectedLocode = null
      if (!this.locodes && this.locodes.length == 0 || !this.locodes.includes(this.selectedLocode)) this.selectedLocode = null;
    }
  },
  methods: {
    nameAndCodeFilter(item, queryText) {
      return (
        (item.code &&
          item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1) ||
        (item.name &&
          item.name
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase())) > -1
      );
    },
  },
};
</script>