<template>
    <div class="text-center">
        <v-dialog v-model="isOpen" width="50%">
            <v-card>
                <v-card-title class="text-h5 primary white--text">
                    Parque {{ park.code }} :: Estatística
                    <v-spacer />
                    <v-btn icon @click="close()" dark>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-progress-linear indeterminate color="yellow darken-2" v-if="loading"></v-progress-linear>

                <v-card-text style="height:50vh" class="pa-5">
                    <v-chart :option="option" autoresize v-if="!!option" />
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import VChart from "vue-echarts";
import * as echarts from "echarts";

export default {
    props: ["open", "selected"],
    components: {
        VChart,
    },
    data() {
        return {
            loading: true,
            option: null,
        };
    },
    computed:
    {
        isOpen: {
            get() {
                return this.open;
            },
            set(val) {
                this.$emit("update:open", val);
            },
        },
        park: {
            get() {
                return this.selected;
            },
            set(val) {
                this.$emit("update:selected", val);
            },
        },
    },
    methods:
    {
        close() {
            this.isOpen = false;
        },

        getStats(id) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch("parks/GET_STATS_PARK", id)
                    .then((data) => {
                        this.loading = false;

                        this.option = {
                            title: [
                                {
                                    text: 'Entradas em Parque',
                                    left: 'center'
                                }
                            ],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                                data: ['Normais', 'Hazmat'],
                                bottom: 10,
                            },
                            toolbox: {
                                show: true,
                                orient: 'vertical',
                                left: 'right',
                                top: 'center',
                                feature: {
                                    mark: { show: true },
                                    dataView: { show: true, readOnly: false },
                                    magicType: { show: true, type: ['line', 'bar', 'stack'] },
                                    restore: { show: true },
                                    saveAsImage: { show: true }
                                }
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: data.map(e => e.datetime)
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: [
                                {
                                    name: 'Normais',
                                    type: 'bar',
                                    barGap: 0,
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    itemStyle: {
                                        color: '#e0f0e3'
                                    },
                                    data: data.map(e => e.total_containers)
                                },
                                {
                                    name: 'Hazmat',
                                    type: 'bar',
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    itemStyle: {
                                        color: '#FD8A8A'
                                    },
                                    data: data.map(e => e.containers_with_hazmat)
                                },
                            ]
                        };
                        resolve();
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        }
    },
    mounted() {
        this.getStats(this.park.id);
    },
}
</script>