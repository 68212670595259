<template>
  <div v-if="this.weatherData && this.weatherData.data.length > 0">
    <v-list-item>
      <v-list-item-avatar size="40" class="mr-2 mt-3" style="float: left">
        <v-img :src="this.weatherData.data[0].icon"></v-img
      ></v-list-item-avatar>
      <v-list-item-content style="float: right">
        <div>
          <p class="ma-1 pa-0" style="font-size: 12px">
            <b>Máx:</b>
            {{ this.weatherData.data[0].tMax }} ºC
            <b>Mín:</b>
            {{ this.weatherData.data[0].tMin }} ºC
          </p>
          <p class="ma-1 pa-0" style="font-size: 12px">
            <b>Hora:</b>
            {{ this.timerData | formatJSFullDatetime }}
          </p>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  data() {
    return {
      weatherData: null,
      timerData: null,
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
  },
  mounted() {
    this.updateWeather();
    this.updateTimer();

    setInterval(() => {
      this.updateWeather();
    }, 60000);

    setInterval(() => {
      this.updateTimer();
    }, 1000);
  },
  methods: {
    updateTimer: function () {
      this.timerData = new Date();
    },
    updateWeather: function () {
      let that = this;
      var request = new XMLHttpRequest();
      request.open(
        "GET",
        `https://api.ipma.pt/open-data/forecast/meteorology/cities/daily/${this.locode.city_identifier}.json`,
        true
      );
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          // Success!
          let response = JSON.parse(request.responseText);
          that.weatherData = {
            ...response,
            data: response.data.map((data) => {
              if (data)
                return {
                  ...data,
                  icon: require("@/assets/weather/w_ic_d_" +
                    (data.idWeatherType ?? 1).toString().padStart(2, "0") +
                    "anim.svg"),
                };
            }),
          };
        } else {
          // We reached our target server, but it returned an error
          console.log("There was a problem retrieving the data");
        }
      };
      request.onerror = function () {
        // There was a connection error of some sort
        console.log("There was a connection error");
      };
      request.send();
    },
  },
};
</script>
