import { settingsService } from "@/api_services/settings.service";

export default {
  namespaced: true,
  actions: {

    GET_LDAP_CONFIGURATIONS: () => {
      return new Promise((resolve, reject) => {
        settingsService
          .getLdapConfigurations()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_LDAP_CONFIGURATIONS: (context, configs) => {
      return new Promise((resolve, reject) => {
        settingsService
          .saveLdapConfigurations(configs)
          .then(() => {
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
