import * as maptalks from 'maptalks';
import * as THREE from 'three';
import {
    BaseObject
} from "maptalks.three";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
//import { GLTFLoader } from 'https://unpkg.com/three@0.140.2/examples/jsm/loaders/GLTFLoader.js';
//default values
var OPTIONS = {
    radius: 100,
    altitude: 0
};

const CARGOS = [{
    "id": 1,
    "code": [20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    "color": "#C03638",
    "name": "Hidroavião"
}, {
    "id": 2,
    "code": [30],
    "color": "#FFC11F",
    "name": "Pesca"
}, {
    "id": 3,
    "code": [31, 52],
    "color": "#9D6666",
    "name": "Rebocador"
}, {
    "id": 4,
    "code": [32, 33],
    "color": "#9D7050",
    "name": "Dragas"
}, {
    "id": 5,
    "code": [34],
    "color": "#9D7050",
    "name": "Mergulho"
}, {
    "id": 6,
    "code": [35],
    "color": "#F34648",
    "name": "Militar"
}, {
    "id": 7,
    "code": [36],
    "color": "#66C547",
    "name": "Veleiro"
}, {
    "id": 8,
    "code": [37],
    "color": "#66C547",
    "name": "Recreio"
}, {
    "id": 9,
    "code": [38, 39],
    "color": "#5EC8BD",
    "name": "Reservado"
}, {
    "id": 10,
    "code": [40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
    "color": "#C259B5",
    "name": "Lancha"
}, {
    "id": 11,
    "code": [50],
    "color": "#8C4EB8",
    "name": "Piloto"
}, {
    "id": 12,
    "code": [51, 53],
    "color": "#F34648",
    "name": "Salva-vidas"
}, {
    "id": 13,
    "code": [54],
    "color": "#F34648",
    "name": "Proteção ambiental"
}, {
    "id": 14,
    "code": [55],
    "color": "#F34648",
    "name": "Forças de segurança"
}, {
    "id": 15,
    "code": [56, 57],
    "color": "#A8A8A8",
    "name": "Substituição"
}, {
    "id": 16,
    "code": [58],
    "color": "#F34648",
    "name": "Transporte médico"
}, {
    "id": 17,
    "code": [60, 61, 62, 63, 64, 65, 66, 67, 68, 69],
    "color": "#FF8A22",
    "name": "Passageiro"
}, {
    "id": 18,
    "code": [70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
    "color": "#3875D7",
    "cargo": true,
    "name": "Mercadorias"
}, {
    "id": 19,
    "code": [80, 81, 82, 83, 84, 85, 86, 87, 88, 89],
    "color": "#444444",
    "name": "Petroleiro"
}, {
    "id": 20,
    "code": [0, 59, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],
    "color": "#A8A8A8",
    "name": "Outro"
}];

/**
 *                                                                                                     
         |-------------> .......::.::.......                              
         |              :^   .::.. ..::.   :.                             
         |              :^  ::        .::  ..                               
         |              :^ ::           :^ ..                             
         |              :^^.             ::..                              
         |              :!:               :~.                           
         |              ^~                 ~.                               
         |              :^                 ..                            
         |              :^                 ..                              
         |              :^                 ..                             
         |              :^                 ..                             
         |              :^                 ..                                 
         A              :^                 ..                               
         |              :^                 ..                               
         |              :^                 ..                             
         |              :^                 ..                               
         |              :^                 ..                 
         |              :^                 ..                  
         |              :^                 ..                    
         |              :^                 ..                   
         |              :^                 ..                  
         |              :^                 ..                    
         |              :^                 ..                 
         |              :^                 ..                  
         |              :^                 ..                
         |------------->:^     O           ..              
         |              :^                 ..               
         |              :^                 ..                      
         B              :^                 ..                      
         |              :^                 ..                     
         |              :^                 :.                     
         |              ^!                 !.                       
         |              :~:::..       ....:::                    
         |------------->.^::^^^:::::::^^^:::.                   
                         ^     ^            ^                                      
                         |     |            |                                  
                         |     |            |                                        
                         |     |            |                                      
                         |     |            |                     
                         |     |            |
                         |--C--|------D-----|
                         
 */
class Ship extends BaseObject {
    constructor(shipInfo, coordinate, options, layer) {
        options = maptalks.Util.extend({}, OPTIONS, options, {
            layer,
            coordinate
        });
        super();
        this._initOptions(options);
        const { altitude } = options;
        let ship;
        let shipMaterial;

        // Obtain Ship Type
        const shipType = this.getShipType(shipInfo.cargo);

        // If the Ship doesnt have HDG or the HDG is higher than 360 or the ship doesnt have dimensions 
        // The Ship will be represented by a sphere
        if (shipInfo.hdg && shipInfo.hdg < 360 && shipInfo.dimA && shipInfo.dimB && shipInfo.dimC && shipInfo.dimD) {

            let shapes = this.createShape(shipInfo.dimA, shipInfo.dimB, shipInfo.dimC, shipInfo.dimD);

            // Create Ship Base
            const baseShipGeometry = this.createGeometry(shapes.base,
                { depth: 3, bevelEnabled: true, bevelSegments: 5, steps: 2, bevelSize: 1, bevelThickness: 1 },
                shipType.color);

            // Create Ship Command Zone
            const commandZoneShipGeometry = this.createGeometry(shapes.satellite,
                { depth: 10, bevelEnabled: true, bevelSegments: 1, steps: 2, bevelSize: 1, bevelThickness: 0 },
                0x000000);

            // Verify if ship transports cargo
            // If it does the ship will appear with container
            // Otherwise it will appear empty
            if (shipType.cargo) {
                // Create Ship Top Container P1
                const topCP1ShipGeometry = this.createGeometry(shapes.containersP1,
                    { depth: 6, bevelEnabled: true, bevelSegments: 1, steps: 2, bevelSize: 1, bevelThickness: 0 },
                    0xFFFFFF);

                // Create Ship Top Container P2
                const topCP2ShipGeometry = this.createGeometry(shapes.containersP2,
                    { depth: 6, bevelEnabled: true, bevelSegments: 1, steps: 2, bevelSize: 1, bevelThickness: 0 },
                    0xFFFFFF);

                // Create Ship Top Container P3
                const topCP3ShipGeometry = this.createGeometry(shapes.containersP3,
                    { depth: 6, bevelEnabled: true, bevelSegments: 1, steps: 2, bevelSize: 1, bevelThickness: 0 },
                    0xFFFFFF);

                // Merge Ship parts in a single geometry
                ship = BufferGeometryUtils.mergeBufferGeometries([baseShipGeometry, topCP1ShipGeometry, topCP2ShipGeometry, topCP3ShipGeometry, commandZoneShipGeometry]);
            } else {
                // Merge Ship parts in a single geometry
                ship = BufferGeometryUtils.mergeBufferGeometries([baseShipGeometry, commandZoneShipGeometry]);
            }
            // Create Ship Material
            shipMaterial = new THREE.MeshPhongMaterial({ vertexColors: true });
        } else {
            ship = new THREE.SphereBufferGeometry(0.1, 16, 16);
            shipMaterial = new THREE.MeshPhongMaterial({ color: shipType.color });
        }


        // Create Ship Mesh
        this._createMesh(ship, shipMaterial);


        if (shipInfo.hdg && shipInfo.hdg < 360) {
            //set object3d rotation
            this.getObject3d().rotateZ(THREE.MathUtils.degToRad(-shipInfo.hdg));
            //set object3d scale
            this.getObject3d().scale.set(0.015, 0.015, 0.015);
        }
        //set object3d position
        const z = layer.distanceToVector3(altitude, altitude).x;
        const position = layer.coordinateToVector3(coordinate, z);
        this.getObject3d().position.copy(position);
    }

    createGeometry(vertices, extrudeOptions, color) {
        // Create Shape with the vertices
        let shape = new THREE.Shape(vertices);
        // Create an extruded geometry from the shape
        let geom = new THREE.ExtrudeBufferGeometry(shape, extrudeOptions);
        // Color the geometry with a specific color
        geom.setAttribute('color', this.obtainMaterial(geom, color));

        return geom;
    }

    obtainMaterial(geom, colorMaterial) {
        // Obtain attribute position from geometry
        const positionAttribute = geom.getAttribute('position');
        // Create Color
        const color = new THREE.Color(colorMaterial);
        // Create and fill array with color(equivalent to coloring the vertices of triangles existing in the geometry)
        const colors = Array(positionAttribute.count).fill([color.r, color.g, color.b]).flat();
        return new THREE.Float32BufferAttribute(colors, 3);
    }

    getShipType(code) {
        for (let i = 0; i < CARGOS.length; i++) {
            let shipClassification = CARGOS[i];
            if (shipClassification) {
                var j = 0;
                for (j = 0; j < shipClassification.code.length; j++) {
                    var shipClassifCode = shipClassification.code[j];
                    if (shipClassifCode == code) {
                        return shipClassification;
                    }
                }
            }
        }

        return CARGOS[CARGOS.length - 1];
    }

    setSymbol(ship, material) {

        let newMat;
        if (material) {
            newMat = material;
        } else {
            if (ship.hdg && ship.hdg < 360) {
                newMat = new THREE.MeshPhongMaterial({ vertexColors: true });
            } else {
                newMat = new THREE.MeshPhongMaterial({ color: this.getShipType(ship.code).color });
            }
        }

        newMat.needsUpdate = true;
        this.getObject3d().material = newMat;
        this._fire('symbolchange', {
            'old': null,
            'new': newMat,
            'target': this
        });

        return this;
    }

    createShape(dimA, dimB, dimC, dimD) {
        let auxCalcBottom = 1;
        let auxCalcFront = 0;
        if (dimB >= dimA) {
            auxCalcBottom = 7;
            auxCalcFront = 2.5;
        }

        let centerX = -dimC + ((dimC + dimD) / 2);
        let frontOffset = dimA - (dimA * (1 / (5 - auxCalcFront)));
        let shipLength = dimB + dimA;

        let baseShipShape = [
            new THREE.Vector3(-dimC, -dimB + (dimB * (1 / (2 * auxCalcBottom))), 0), // Left Back
            new THREE.Vector3(-dimC + ((dimC + dimD) / 4), -dimB, 0), // Left Back
            new THREE.Vector3(dimD - ((dimC + dimD) / 4), -dimB, 0), // Right Back
            new THREE.Vector3(dimD, -dimB + (dimB * (1 / (2 * auxCalcBottom))), 0), // Right Back
            new THREE.Vector3(dimD, frontOffset, 0), // Right Front
            new THREE.Vector3(dimD - ((dimC + dimD) / 4), frontOffset + (frontOffset * (1 / (5 - auxCalcFront))), 0), // Right Front
            new THREE.Vector3(centerX, dimA, 0), // Middle Front
            new THREE.Vector3(-dimC + ((dimC + dimD) / 4), frontOffset + (frontOffset * (1 / (5 - auxCalcFront))), 0), // Left Front
            new THREE.Vector3(-dimC, frontOffset, 0), // Left Front
        ];

        let containersP1 = [
            new THREE.Vector3(-dimC + ((dimC + dimD) / 6), -dimB + (dimB * (1 / (2 * auxCalcBottom))), 0),
            new THREE.Vector3(dimD - ((dimC + dimD) / 6), -dimB + (dimB * (1 / (2 * auxCalcBottom))), 0),
            new THREE.Vector3(dimD - ((dimC + dimD) / 6), -dimB + (shipLength / 4), 0),
            new THREE.Vector3(-dimC + ((dimC + dimD) / 6), -dimB + (shipLength / 4), 0),
        ];

        let containersP2 = [
            new THREE.Vector3(-dimC + ((dimC + dimD) / 6), -dimB + (shipLength / 3.5), 0),
            new THREE.Vector3(dimD - ((dimC + dimD) / 6), -dimB + (shipLength / 3.5), 0),
            new THREE.Vector3(dimD - ((dimC + dimD) / 6), -dimB + ((shipLength / 4) * 2), 0),
            new THREE.Vector3(-dimC + ((dimC + dimD) / 6), -dimB + ((shipLength / 4) * 2), 0),
        ];

        let containersP3 = [
            new THREE.Vector3(-dimC + ((dimC + dimD) / 6), -dimB + (shipLength / 3.5) + (shipLength / 4), 0),
            new THREE.Vector3(dimD - ((dimC + dimD) / 6), -dimB + (shipLength / 3.5) + (shipLength / 4), 0),
            new THREE.Vector3(dimD - ((dimC + dimD) / 6), -dimB + ((shipLength / 4) * 3), 0),
            new THREE.Vector3(-dimC + ((dimC + dimD) / 6), -dimB + ((shipLength / 4) * 3), 0),
        ];

        let satellite = [
            new THREE.Vector3(-0.1, -0.2, 0),
            new THREE.Vector3(0, -0.4, 0),
            new THREE.Vector3(0.1, -0.2, 0),
            new THREE.Vector3(0.1, 0.2, 0),
            new THREE.Vector3(0, 0.4, 0),
            new THREE.Vector3(-0.1, 0.2, 0),
        ];
        return { base: baseShipShape, containersP1: containersP1, containersP2: containersP2, containersP3: containersP3, satellite: satellite };
    }

    generateRandomColor() {
        return Math.floor(Math.random() * 16777215);
    }
}

export default Ship;
