import Vue from "vue";
import { typesService } from "@/api_services/types.service";

let initialState = { list: [] };

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    setTypes(state, types) {
      state.list = types;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    GET_TYPES: (context) => {
      return new Promise((resolve, reject) => {
        typesService
          .getTypes()
          .then((res) => {
            context.commit("setTypes", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_TYPE: (context, type) => {
      return new Promise((resolve, reject) => {
        typesService
          .updateType(type)
          .then(({data}) => {
            context.dispatch("GET_TYPES");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE_TYPE: (context, id) => {
      return new Promise((resolve, reject) => {
        typesService
          .deleteType(id)
          .then(() => {
            context.dispatch("GET_TYPES");
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE_TYPE: (context, type) => {
      return new Promise((resolve, reject) => {
        typesService
          .createType(type)
          .then(({data}) => {
            context.dispatch("GET_TYPES");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET_STATE_TYPES: (context) => {
      context.commit("resetState");
    },
    IMPORT_TYPES: (context, file) => {
      return new Promise((resolve, reject) => {
        typesService
          .importTypes(file)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_TYPES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
