<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div v-for="(message, index) in this.messages" :key="index" class="subtitle">
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear height="10" striped color="yellow darken-2" indeterminate v-show="loading"></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("global.edit_operator") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
          <v-text-field counter="4" maxlength="4" v-model="itemToEdit.code" required :label="$t('operators.code')"
            outlined disabled />

          <v-text-field counter="255" maxlength="255" v-model="itemToEdit.name" required :label="$t('operators.name')"
            :rules="nameRules" outlined />

          <v-text-field counter="255" maxlength="255" v-model="itemToEdit.country" required
            :label="$t('operators.country')" :rules="countryRules" outlined />

          <v-text-field counter="255" maxlength="255" v-model="itemToEdit.city" required :label="$t('operators.city')"
            :rules="cityRules" outlined />

          <v-select :items="filteredColors" item-text="name" :label="$t('operators.texture')" v-model="itemToEdit.color"
            :rules="colorRules" outlined>
            <template v-slot:prepend-inner>
              <v-icon :color="itemToEdit.color.toLowerCase()">mdi-square-rounded</v-icon>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon :color="data.item.value.toLowerCase()" left>mdi-square-rounded</v-icon>{{ data.item.name
                  }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="save" :disabled="loading">
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      itemToEdit: this.cloneItem(this.item),
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      countryRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      cityRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      colorRules: [(v) => !!v || this.$t("rules.required_field")],
      colors: [
        {
          name: this.$t('global.blue'), value: 'BLUE'
        },
        {
          name: this.$t('global.brown'), value: 'BROWN'
        },
        {
          name: this.$t('global.grey'), value: 'GREY'
        },
        {
          name: this.$t('global.green'), value: 'GREEN'
        },
        {
          name: this.$t('global.orange'), value: 'ORANGE'
        },
        {
          name: this.$t('global.yellow'), value: 'YELLOW'
        },
      ]
    };
  },
  watch: {
    open(newVal) {
      if (newVal) {
        this.itemToEdit = this.cloneItem(this.item);
      }
    },
    item(newVal) {
      this.itemToEdit = this.cloneItem(newVal);
    },
  },
  computed: {
    findColor() {
      return this.itemToEdit.color ? this.colors.find((color) => { return color.name == this.itemToEdit.color })?.value.toLowerCase() : 'gray';
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    filteredColors() {
      return this.colors;
    },
  },
  methods: {
    cloneItem(item) {
      return JSON.parse(JSON.stringify(item));
    },
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store.dispatch("operators/UPDATE_OPERATOR", this.itemToEdit).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
