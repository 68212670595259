import { usersService } from "@/api_services/users.service";
import Vue from "vue";

let initialState = {
  usersList: [],
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },

    setUsers(state, users) {
      state.usersList = users;
    },
  },
  actions: {
    GET_USERS: (context) => {
      return new Promise((resolve, reject) => {
        usersService
          .getUsers()
          .then((res) => {
            context.commit("setUsers", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_USER: (context, user) => {
      return new Promise((resolve, reject) => {
        usersService
          .createUser(user)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_USER: (context, user) => {
      return new Promise((resolve, reject) => {
        usersService
          .updateUser(user)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_USER_PERMISSIONS: (context, { user, permissions }) => {
      return new Promise((resolve, reject) => {
        usersService
          .updateUserPermissions(user, permissions)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_USER: (context, userId) => {
      return new Promise((resolve, reject) => {
        usersService
          .deleteUser(userId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_LDAP_USERS: () => {
      return new Promise((resolve, reject) => {
        usersService
          .getLdapUsers()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    IMPORT_USERS: (context, users) => {
      return new Promise((resolve, reject) => {
        usersService
          .importLdapUsers(users)
          .then(() => {
            context.dispatch("GET_USERS");
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
