let Constants = {
  //  install
  install(Vue) {
    //  create global Constants
    Vue.Constants = {
      
    };
  }
};

export default Constants;