import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const segregationsService = {
  getSegregations,
  updateSegregation,
  deleteSegregation,
  createSegregation,
  importSegregations,
};

async function getSegregations() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/segregations",
    requestOptions
  );
}

async function updateSegregation(segregation) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/segregations/" + segregation.id,
    {
      name: segregation.name,
      color: segregation.color,
      label: segregation.label,
    },
    requestOptions
  );
}

async function deleteSegregation(id) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/segregations/" + id,
    requestOptions
  );
}

async function createSegregation(type) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/segregations",
    type,
    requestOptions
  );
}

async function importSegregations(file) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/segregations/import",
    bodyFormData,
    requestOptions
  );
  return res;
}
