import Vue from "vue";
import {
  parksService
} from "@/api_services/parks.service";

let initialState = {
  list: [],
  geojson: [],
};

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    setParks(state, parks) {
      state.list = parks;
    },
    setGeoJsonParks(state, geojson) {
      state.geojson = geojson;
    },
    updatePark(state, park) {
      let index = state.list.findIndex((p) => p.code === park.code);
      if (index !== -1) {
        Vue.set(state.list, index, park);
      }
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_PARKS: (context) => {
      return new Promise((resolve, reject) => {
        parksService
          .getParks()
          .then((res) => {
            context.commit("setParks", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_PARKS_GEOJSON: (context) => {
      return new Promise((resolve, reject) => {
        parksService
          .getGeoJson()
          .then((res) => {
            context.commit("setGeoJsonParks", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET_STATE_PARKS: (context) => {
      context.commit("resetState");
    },
    IMPORT_PARKS: (context, file) => {
      return new Promise((resolve, reject) => {
        parksService
          .importParks(file)
          .then(({ data }) => {
            context.dispatch("GET_PARKS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_PARK: (context, park) => {
      return new Promise((resolve, reject) => {
        parksService
          .updatePark(park)
          .then(({ data }) => {
            context.dispatch("GET_PARKS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE_PARK: (context, parkId) => {
      return new Promise((resolve, reject) => {
        parksService
          .deletePark(parkId)
          .then(({ data }) => {
            context.dispatch("GET_PARKS");
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_STATS_PARK: (context, parkId) => {
      return new Promise((resolve, reject) => {
        parksService
          .statsPark(parkId)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    REFRESH: (context, park) => {
      return new Promise((resolve, reject) => {
        if (!!park.code) {
          park.loading = true;
          context.commit("updatePark", park);
          parksService
            .refresh(park.code)
            .then((res) => {
              res.data.loading = false;
              context.commit("updatePark", res.data);
              resolve(res.data);
            })
            .catch((e) => {
              console.error(e);
              park.loading = false;
              context.commit("updatePark", park);
              reject(e);
            });
        } else {
          resolve();
        }
      });
    },
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};